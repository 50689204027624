import { createContext, useCallback, useContext, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import useSWR from 'swr';

import { API } from 'helpers/api';
import { MusicIDHistory } from 'components/Modules/MusicID/Music.History';
import { StremmerProfile } from './Profile';

import './scss/Channel.scss';

const ChannelContext = createContext<{ api: (path: string, search?: Record<string, any>) => Promise<any>; channel: ChannelProfile }>(null);

export function useChannel() {
	return useContext(ChannelContext);
}

export default function StremmerChannel(): JSX.Element {
	const [channel, setChannel] = useState<ChannelProfile>(null);
	// const [channel, setChannel] = useState<UserChannel>(null);
	const params = useParams<{ username?: string }>();

	// const derp = useSWR(`/strem/${params.username}`, () => API.get(`/channels/${params.username}`));

	const getChannelData = useCallback(
		async (path = '', query = {}): Promise<unknown> => {
			try {
				return await API.get(`/channels/${params.username}/${path}`, { params: query });
			} catch (error) {
				console.error(error);
				return null;
			}
		},
		[params.username],
	);

	useLayoutEffect(() => {
		const load = async () => {
			try {
				const data = await getChannelData();
				setChannel(data as ChannelProfile);
			} catch (error) {
				console.error(error);
			}
		};
		if (params.username && !channel) {
			load();
		}
	}, [channel, getChannelData, params]);

	const value = {
		api: getChannelData,
		channel,
	};

	return channel ? (
		<ChannelContext.Provider value={value}>
			<div className="content">
				<StremmerProfile />
				<MusicIDHistory />
			</div>
		</ChannelContext.Provider>
	) : null;
}

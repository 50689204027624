import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18next
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		preload: ['en'],
		ns: ['common'],
		defaultNS: 'common',
		backend: { loadPath: `/locales/{{lng}}/{{ns}}.json` },
	});

export default i18next;

import type { Egg } from '@redux-eggs/core';
import type { AppStore } from 'services/store';
import { variationsUnload } from './actions';
import { eventsReducer } from './reducers';

import { eventsSagas } from './sagas';

export * from './actions';
export * from './sagas';
export * from './selectors';
export * from './utils';

export function getEventsEgg(): Egg<AppStore> {
	return {
		id: 'events',
		keep: true,
		sagas: [eventsSagas],
		reducersMap: {
			events: eventsReducer,
		},
	};
}

export function getEventsManagerEgg(id?: string): Egg<AppStore> {
	return {
		id: `events-manager`,
		beforeRemove(store) {
			store.dispatch(variationsUnload());
		},
	};
}

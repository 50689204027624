import { actionChannel, all, call, cancelled, put, select, spawn, take, takeEvery } from 'redux-saga/effects';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RuleProperties } from 'json-rules-engine';

import { eventEmulate, eventMessage, eventSource, eventsReady, eventsRegister } from '../actions';
import { getEventHistory, selectEventSources } from '../selectors';
import { overlayLoaded } from 'components/Overlays/Eggs/Overlay';
import { eventChannel, END } from 'redux-saga';

type EventsWorker = ReturnType<typeof createWebWorker>;

export function* eventWebWorkerSaga() {
	const worker = createWebWorker();
	yield all([spawn(eventSourcesWorker), spawn(eventBroadcastChannelSaga)]);
	yield takeEvery(eventsRegister, registerWorkerEvent, worker);
	yield takeEvery(eventEmulate, eventEmulationHandler, worker);
}

function* registerWorkerEvent(worker: EventsWorker, { payload }: PayloadAction<any>) {
	const events = [...(payload?.rules || [])]?.map((rule: RuleProperties) => rule.event.type);
	const sources = yield select(selectEventSources);
	if (events.length) {
		yield call([worker, worker.postMessage], { uuid: sources[0], type: `events`, data: { events: [...events] } });
	}
}

function* eventEmulationHandler(worker: EventsWorker, { payload }: PayloadAction<any>) {
	const sources = yield select(selectEventSources);
	yield call([worker, worker.postMessage], { uuid: sources[0], type: `emulate`, data: { ...payload } });
}

/* -------------------------------------------------- */

function createWebWorker(dedicated = false) {
	if (dedicated === false && 'SharedWorker' in globalThis) {
		const worker = new SharedWorker('/workers/events-worker.js', { name: 'Events Manager: Shared', type: 'module', credentials: 'same-origin' });
		worker.port.onmessage = (message) => console.debug(`[EventsManager: Shared]:`, message.data);
		worker.port.start();
		return worker.port;
	} else {
		const worker = new Worker(`/workers/dedicated-worker.js`, { name: 'Events Manager: Dedicated', type: 'module', credentials: 'same-origin' });
		worker.onmessage = (message) => console.debug(`[EventsManager: Dedicated]:`, message.data);
		return worker;
	}
}

/* -------------------------------------------------- */

export function* eventSourcesWorker() {
	// const sourceChannel = yield actionChannel([overlayLoaded, overlayLoad.fulfilled]);
	while (true) {
		try {
			const { payload } = yield take(overlayLoaded);
			const sources = yield select(selectEventSources);
			if (sources.includes(payload.account) == false) {
				yield put(eventSource({ id: payload.account }));
			}
		} catch (error) {
			console.error(error);
		}
	}
}

/* -------------------------------------------------- */

function* eventBroadcastChannelSaga() {
	const sources = new Set<string>();
	const sourceChannel = yield actionChannel([eventSource]);
	while (true) {
		try {
			const { payload } = yield take(sourceChannel);
			if (sources.has(payload.id) === false) {
				yield spawn(broadcastChannelWorker, payload.id);
				sources.add(payload.id);
			}
		} catch (error) {
			console.error(error);
		}
	}
}

function* broadcastChannelWorker(id: string) {
	const broadcastChannel = eventChannel<MessageEvent<OverlayEventData>>((emitter) => {
		const broadcast = new BroadcastChannel(id);
		const listener = (message: MessageEvent<any>) => {
			emitter({ ...message.data });
		};
		broadcast.addEventListener('message', listener);
		return () => {
			broadcast.removeEventListener('message', listener);
			broadcast.close();
			emitter(END);
		};
	});
	yield put(eventsReady({ id }));
	while (true) {
		try {
			const event: OverlayEventMessage = yield take(broadcastChannel);
			const history = yield select(getEventHistory, id);
			if (history) {
				// Old news, we'll skip it
				continue;
			}
			const message = yield parseEventMessage({ ...event });
			yield put(eventMessage(message));
		} catch (error) {
			console.error(error);
		} finally {
			if (yield cancelled()) {
				broadcastChannel.close();
			}
		}
	}
}

/* -------------------------------------------------- */

function parseEventMessage(message: OverlayEventMessage) {
	const { data } = { ...message };
	message.data = {
		...data,
		flags: {
			emulated: false,
			instant: false,
			skippable: true,
			...(data?.flags || {}),
		},
	};
	return message;
}

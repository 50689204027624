import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { localStorageLoad } from 'services/store/utils/browserStorage';

import { accountSettings, userAccounts, userLogout, userProfile } from '../actions';
import { initialUserState } from '../state';

export const userSlice = createSlice({
	name: 'user',
	initialState: () => {
		const state = localStorageLoad('stremmer');
		return state?.user ? { ...initialUserState, ...state.user, loaded: true, error: false } : { ...initialUserState };
	},
	reducers: {},
	extraReducers(builder) {
		builder.addCase(accountSettings.fulfilled, () => {
			toast.success(`Settings saved`);
		});
		builder.addCase(userLogout, () => {
			return { ...initialUserState };
		});
		// builder.addCase(userLogout.fulfilled, () => {
		// 	return { ...initialUserState };
		// });
		builder.addCase(userAccounts, (state, { payload }) => {
			if (payload) {
				state.accounts = [...payload];
			}
		});

		builder
			.addCase(userProfile.pending, (state) => {
				state.loaded = false;
				state.error = false;
			})
			.addCase(userProfile.fulfilled, (state, { payload }) => {
				state.accounts = payload.accounts;
				state.loaded = true;
				state.error = false;
			});
	},
});

import { createAction } from '@reduxjs/toolkit';
import type { RuleProperties } from 'json-rules-engine';

export * from './actions/conditions';
export * from './actions/variations';

// EventStream setup actions
export const eventSource = createAction<{ id: string }>(`@@events/source`);
export const eventsReady = createAction<{ id: string }>(`@@events/ready`);

// Register events with the worker and SSE
export const eventsRegister = createAction<{ id: string; rules: RuleProperties[] }>(`@@events/register`);
// export const eventsRegister = createAction<RuleProperties[]>(`@@events/register`);

// Inbound events
export const eventMessage = createAction<OverlayEventMessage>(`@@events/message`);

// Store in Redux
export const eventsHistory = createAction<OverlayEventMessage>(`@@events/history`);

// Add to actionChannel / Event queue
export const eventActive = createAction<OverlayEventQueued>(`@@events/active`);
export const eventQueued = createAction<OverlayEventQueued>(`@@events/queue`);
// export const eventReplay = createAction<OverlayEventQueued>(`@@events/replay`);

// Set the active status of an event
export const eventStatus = createAction<OverlayEventStatus>(`@@events/status`);

// Control actions - Skip, Cancel, and clear the queue entirely
export const eventSkip = createAction<void>(`@@events/skip`);
export const eventCancel = createAction<void>(`@@events/cancel`);

// Purge the action queue
export const eventsClear = createAction<void>(`@@events/clear`);

// --
// export const eventActive = createAction<any>(`@@events/active`);

export const eventVariables = createAction<any>(`@@events/variables`);

export const eventEmulate = createAction<OverlayEventMessage>(`@@events/emulate`);

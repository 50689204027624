import { actionChannel, put, select, spawn, take } from 'redux-saga/effects';

import { assetsFetch } from 'components/Assets/Egg';
import { selectModule } from 'components/Overlays/Eggs/selectors';

import { fontsLoad } from '../Fonts/actions';
import { moduleLoaded, moduleUpdate } from './actions';

export function moduleSagas(id: string) {
	return function* () {
		yield spawn(moduleWorker, id);
		// yield all([fork(moduleWorker, id)]);
	};
}
/* -------------------------------------------------- */

function* moduleWorker(id?: string) {
	// const moduleChannel = yield actionChannel([moduleCSS, moduleLoaded, moduleUpdate]);
	const moduleChannel = yield actionChannel((action: any) => (moduleLoaded.match(action) || moduleUpdate.match(action)) && action.payload.id === id);
	while (true) {
		try {
			const action = yield take(moduleChannel);
			const { payload } = action;
			const module: OverlayModule = yield select(selectModule, id);
			if (moduleLoaded.match(action) || payload.assets) {
				yield spawn(moduleAssetsSaga, module);
			}
			if (moduleLoaded.match(action) || payload.css) {
				yield spawn(moduleFontsSaga, module);
			}
		} catch (error) {
			console.error(error);
		}
	}
}
/* -------------------------------------------------- */

function* moduleFontsSaga(module?: OverlayModule) {
	try {
		// Load Fonts
		const list = Object.values({ ...(module?.variations || {}) }).map(({ css }) => css?.fontFamily);
		const fonts = [...list, module?.css?.fontFamily].filter((font) => font);
		if (fonts.length) {
			yield put(fontsLoad({ fonts }));
		}
	} catch (error) {
		console.error(error);
	}
}

/* -------------------------------------------------- */

function* moduleAssetsSaga(module?: OverlayModule) {
	try {
		// Load Assets
		const derp = Object.values({ ...(module?.assets || {}) }).map((asset) => asset?.id);
		const list = Object.values({ ...(module?.variations || {}) }).flatMap(({ assets }) => {
			return Object.values({ ...(assets || {}) }).map((asset) => asset?.id);
		});
		const assets = Array.from(new Set([...list, ...derp]).values()).filter((item) => item);
		if (assets.length) {
			yield put(assetsFetch({ assets: [...assets] }));
		}
	} catch (error) {
		console.error(error);
	}
}

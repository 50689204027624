import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './scss/Main.scss';

export function MainFooter() {
	const { t } = useTranslation();
	return (
		<>
			<footer id="stremmer-footer">
				<ul>
					<li>
						<a href="https://status.stremmer.dev" className="stremmer-text" target="_blank" rel="noopener noreferrer">
							{t(`common:footer.status`)}
						</a>
					</li>
					<li>
						<Link className="stremmer-text" to="/privacy">
							{t(`common:footer.privacy`)}
						</Link>
					</li>
				</ul>
			</footer>
		</>
	);
}

import { createReducer, nanoid } from '@reduxjs/toolkit';
import { conditionsAdd, conditionsDelete, conditionsRuleAdd, conditionsRuleDelete, conditionsRuleUpdate, conditionsUpdate } from '../actions';
import { createCondition, createConditionRule } from '../utils';

export const conditionsReducer = createReducer<OverlayModuleVariation['events']>({}, (builder) => {
	builder.addCase(conditionsAdd, (state) => {
		const id = nanoid();
		state.conditions = {
			...state.conditions,
			...createCondition(id),
		};
		state.rules = {
			...state.rules,
			...createConditionRule(id),
		};
	});

	builder.addCase(conditionsDelete, (state, { payload }) => {
		const { id } = payload;
		if (state.conditions[id]) {
			delete state.conditions[id];
			Object.values({ ...state.rules }).forEach((rule) => {
				if (rule.condition === id) {
					delete state.rules[rule.id];
				}
			});
		}
	});

	builder.addCase(conditionsUpdate, (state, { payload }) => {
		const { id, ...condition } = payload;
		state.conditions[id] = {
			...state.conditions[id],
			...(condition || {}),
		};
	});

	/* -------------------------------------------------- */

	builder.addCase(conditionsRuleAdd, (state, { payload }) => {
		const { id = nanoid() } = payload;
		if (!state.conditions[id]) {
			state.conditions = {
				...state.conditions,
				...createCondition(id),
			};
		}
		state.rules = {
			...state.rules,
			...createConditionRule(id),
		};
	});

	builder.addCase(conditionsRuleDelete, (state, { payload }) => {
		const { id } = payload;
		if (state.rules[id]) {
			const condition = state.rules[id].condition;
			delete state.rules[id];
			const rules = Object.values({ ...(state.rules || {}) }).filter((rule) => rule.condition === condition);
			if (rules.length === 0 && state.conditions[condition]) {
				delete state.conditions[condition];
			}
		}
	});

	builder.addCase(conditionsRuleUpdate, (state, { payload }) => {
		const { id, rule } = payload;
		state.rules[id] = { ...state.rules[id], ...rule };
	});
});

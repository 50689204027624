import { createSlice } from '@reduxjs/toolkit';

import { setProperty } from 'dot-prop';

import { localStorageLoad } from 'services/store';

import { createVariation } from 'components/Events/Egg';

import { moduleContent, moduleMetadata, modulePatch, moduleUpdate, variationAdd } from './actions';
import { selectModules } from '../selectors';

export const modulesSlice = createSlice({
	name: `modules`,
	initialState: (): OverlayState['modules'] => {
		const modules = selectModules(localStorageLoad(`stremmer`));
		return modules ? { ...modules } : {};
	},
	reducers: {},
	extraReducers(builder) {
		builder.addMatcher(
			(action) => action.startsWith(`module/`) && action.payload?.id,
			(state, action) => {
				const { id } = action.payload;
				if (state[id]) {
					state[id] = moduleSlice.reducer(state[id], action);
				}
			},
		);
	},
});

export const moduleSlice = createSlice({
	name: `module`,
	initialState: (): OverlayModule => {
		return {} as OverlayModule;
	},
	reducers: {},
	extraReducers(builder) {
		builder.addCase(moduleContent, (state, { payload }) => {
			Object.entries({ ...payload }).forEach(([key, values]: [string, any]) => {
				if (typeof values === 'string') {
					return;
				}
				state[key] = { ...state[key], ...values };
			});
		});
		builder.addCase(modulePatch, (state, { payload }) => {
			const { id, ...updates } = payload;
			Object.entries({ ...(updates || {}) }).forEach(([key, value]) => {
				setProperty(state, key, value);
			});
		});

		builder.addCase(moduleUpdate, (state, { payload }) => {
			const { id, ...updates } = payload;
			Object.entries({ ...(updates || {}) }).forEach(([key, value]) => {
				setProperty(state, key, value);
			});
			// Object.entries({ ...payload }).forEach(([key, contents]: [string, any]) => {
			// 	if (typeof contents === 'string') {
			// 		return;
			// 	}
			// 	state[key] = Object.entries({ ...contents }).reduce(
			// 		(all, [subkey, values]: [string, any]) => {
			// 			if (values === undefined) {
			// 				delete all[subkey];
			// 				return { ...all };
			// 			}
			// 			const value = typeof values === 'string' ? values : Array.isArray(values) ? [...all[subkey], ...values] : { ...all[subkey], ...values };
			// 			return { ...all, [subkey]: value };
			// 		},
			// 		{ ...(state[key] || {}) },
			// 	);
			// });
		});
		builder.addCase(moduleMetadata, (state, { payload }) => {
			state.metadata = { ...state.metadata, ...payload.metadata };
		});

		builder.addCase(variationAdd, (state, { payload }) => {
			const variation = createVariation(payload.event);
			state.variations = {
				...(state.variations || {}),
				...variation,
			};
		});

		builder.addCase(`module/replace`, (state, { payload }: any) => {
			Object.entries({ ...payload }).forEach(([key, values]: [string, any]) => {
				state[key] = values;
			});
		});
	},
});

import { addListener as addReduxListener } from '@reduxjs/toolkit';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';

import type { PayloadAction, ThunkAction, TypedAddListener } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';

import { configureStore } from './utils/configureStore';
import { createReduxHistory } from './Eggs/Router';

export const store = configureStore({ name: 'stremmer' });
export const history = createReduxHistory(store);

export type AppStore = ReturnType<typeof configureStore>;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, PayloadAction>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useReduxDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useReduxSelector;
export const addAppListener: TypedAddListener<AppState> = addReduxListener;

export const useDispatch = useAppDispatch;
export const useSelector = useAppSelector;
export const addListener = addAppListener;

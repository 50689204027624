import { createReducer, createSlice, current } from '@reduxjs/toolkit';

import { localStorageLoad } from 'services/store';
// import { sessionStorageLoad } from 'services/store';

import { selectLayers } from '../Layer/selectors';
import { layerBounds, layerSort, layerStatus } from './actions';

export const layersSlice = createSlice({
	name: `layers`,
	initialState: (): OverlayState['layers'] => {
		// const layers = selectLayers(sessionStorageLoad(`stremmer`));
		// const layers = selectLayers(localStorageLoad(`stremmer`));
		// return layers ? [...layers] : [];
		// return { ...(layers || {}) };
		return {};
	},
	reducers: {},
	extraReducers(builder) {
		builder.addCase(layerBounds.fulfilled, (state, { payload }) => {
			[...payload].forEach((item) => {
				const { id, bounds } = item;
				if (state[id]) {
					state[id].bounds = { ...state[id].bounds, ...bounds };
				}
			});
		});

		builder.addCase(layerSort, (state, { payload }) => {
			const { from, to } = payload;
			const list = Object.values(state)
				.map((layer) => ({ id: layer.id, zIndex: layer.bounds.zIndex }))
				.sort((a, b) => b.zIndex - a.zIndex);
			const [item] = list.splice(from, 1);
			list.splice(to, 0, item);
			list.map((layer, idx) => {
				state[layer.id].bounds.zIndex = list.length - idx;
			});
		});

		builder.addCase(layerStatus, (state, { payload }) => {
			const { id, status } = payload;
			if (state[id]) {
				state[id].status = { ...state[id].status, ...status };
			}
		});

		// builder.addMatcher(isAnyOf(layerBounds.fulfilled, layerBounds.fulfilled), (state, action) => {
		// 	const { id } = action.payload;
		// 	state[id] = layerReducer(state[id], action);
		// });
	},
});

export const layerReducer = createReducer<OverlayLayer>({}, (builder) => {
	builder.addCase(layerBounds.fulfilled, (state, { payload }) => {
		state.bounds = { ...state.bounds, ...payload };
	});
	builder.addCase(layerStatus, (state, { payload }) => {
		state.status = { ...state.status, ...payload.status };
	});
});

import { composeSelector } from 'services/store/utils/composeSelector';

import type { AppState } from 'services/store';

import { selectOverlay } from '../Overlay/selectors';

export const selectLayers: (state: AppState) => OverlayLayer[] = composeSelector(
	(state: AppState) => selectOverlay(state)?.layers,
	(layers: Record<string, OverlayLayer>) => {
		return Object.values({ ...(layers || {}) }).sort((a, b) => b.bounds.zIndex - a.bounds.zIndex);
	},
);

export const selectLayer: (state: AppState, id: string) => OverlayLayer = composeSelector(
	(state: AppState, id: string) => selectOverlay(state)?.layers?.[id],
	(layer: OverlayLayer) => layer,
);

export const selectLayerBounds: (state: AppState, id: string) => OverlayLayerBounds = composeSelector(
	(state: AppState, id: string) => selectLayer(state, id)?.bounds,
	(bounds: OverlayLayerBounds) => bounds,
);

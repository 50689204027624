import classcat from 'classcat';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { PropsWithChildren } from 'react';

import './Forms.scss';

type CheckboxProps = {
	checked?: boolean;
	value?: string;
	isBoolean?: boolean;
	onChange?: (data: any) => void;
	[x: string]: any;
};

type RangeProps = {
	value?: number;
	onChange?: (data: any) => void;
	[x: string]: any;
};

type ColorProps = {
	value?: string;
	onChange?: (data: any) => void;
	[x: string]: any;
};

type SelectProps = {
	value?: string;
	onChange?: (data: any) => void;
	[x: string]: any;
};

export function Input({ label = null, floating = true, onChange, placeholder = '', value = '', ...props }: PropsWithChildren<any>): JSX.Element {
	const [text, setText] = useState<string>(value);

	useEffect(() => {
		setText(value);
	}, [value]);

	const handleChange = useCallback(
		(event) => {
			const { name, type, value: val } = event.target;
			const value = type === 'number' && Number.isNaN(val) ? 0 : val;
			if (typeof onChange === 'function') {
				// onChange(event);
				onChange({ [name]: type === 'number' ? Number(value) : value });
			}
			setText(value);
		},
		[onChange],
	);

	return (
		<div className={classcat([{ 'form-floating': floating }])}>
			<input {...props} className="form-control" value={text} placeholder={placeholder || label || ''} onChange={handleChange} />
			{label !== null ? <label>{label}</label> : null}
		</div>
	);
}

export function Text({ label = null, floating = true, onChange, placeholder = '', value = '', ...props }: PropsWithChildren<any>): JSX.Element {
	const [text, setText] = useState<string>(value);

	useEffect(() => {
		setText(value);
	}, [value]);

	const handleChange = useCallback(
		(event) => {
			const { name, type, value: val } = event.target;
			const value = type === 'number' && Number.isNaN(val) ? 0 : val;
			if (typeof onChange === 'function') {
				// onChange(event);
				onChange({ [name]: type === 'number' ? Number(value) : value });
			}
			setText(value);
		},
		[onChange],
	);

	return (
		<div className={classcat([{ 'form-floating': floating }])}>
			<textarea {...props} className="form-control" value={text} placeholder={placeholder} onChange={handleChange} />
			{label ? <label>{label}</label> : null}
		</div>
	);
}

// export function RangeInput({ isMilliseconds = false, max = Number.MAX_SAFE_INTEGER, min = 0, step = 1, value = 0, ...props }: any): JSX.Element {
export function RangeInput({ isMilliseconds = false, max, min = 0, name, step = 1, value = 0, onChange }: any): JSX.Element {
	const inputRef = useRef<HTMLInputElement>(null);
	const rangeRef = useRef<HTMLInputElement>(null);
	const [displayMinMax, setDisplayMinMax] = useState(null);
	const [displayStep, setDisplayStep] = useState<number>(null);
	const [displayValue, setDisplayValue] = useState<number>(null);

	useEffect(() => {
		try {
			inputRef.current.defaultValue = String(displayValue ?? 0);
			rangeRef.current.defaultValue = String(displayValue ?? 0);
		} catch (error) {
			console.error(error);
		}
	}, [displayValue]);

	const getActualValue = useCallback(
		(value: number) => {
			return Number(isMilliseconds ? value * 1000 : value);
		},
		[isMilliseconds],
	);

	const getDisplayValue = useCallback(
		(value: number) => {
			return Number(isMilliseconds ? value / 1000 : value);
		},
		[isMilliseconds],
	);

	useEffect(() => {
		const range = {
			min: getDisplayValue(min ?? 0),
			max: getDisplayValue(max ?? 100),
		};
		setDisplayMinMax({ ...range });
	}, [getDisplayValue, max, min]);

	useEffect(() => setDisplayStep(getDisplayValue(step)), [getDisplayValue, step]);
	useEffect(() => setDisplayValue(getDisplayValue(value)), [getDisplayValue, value]);

	const handleChange = useCallback(
		({ target }) => {
			const { value } = target;
			setDisplayValue(value);
			onChange({ [name]: getActualValue(value) });
		},
		[getActualValue, name, onChange],
	);

	const handleRange = useCallback(({ target }) => {
		setDisplayValue(target.value);
	}, []);

	const props = useMemo(() => {
		return {
			max: displayMinMax?.max ?? 100,
			min: displayMinMax?.min ?? 0,
			step: displayStep,
		};
	}, [displayMinMax, displayStep]);

	return (
		<>
			<div className="range-input">
				<input type="range" ref={rangeRef} {...props} defaultValue={displayValue} onBlur={handleChange} onMouseUp={handleChange} onChange={handleRange} />
				<input type="number" ref={inputRef} {...props} defaultValue={displayValue} onChange={handleChange} />
			</div>
		</>
	);
}

export function Range({ onChange, min = 0, max = Number.MAX_SAFE_INTEGER, name, value = 1, ...props }: RangeProps): JSX.Element {
	const [text, setText] = useState<number>(value ?? null);

	useEffect(() => {
		setText(value);
	}, [value]);

	useEffect(() => {
		if (min > text || text > max) {
			const value = Math.max(min, Math.min(max, text));
			setText(value);
			onChange({ [name]: Number(value) });
		}
	}, [max, min, name, onChange, text]);

	const handleChange = useCallback(
		(event) => {
			const { name, value } = event.target;
			setText(Number(value));
			if (typeof onChange === 'function') {
				onChange({ [name]: Number(value) });
			}
		},
		[onChange],
	);

	const handleChaos = useCallback((event) => {
		setText(event.target.value);
	}, []);

	return (
		<input
			{...props}
			className="form-range"
			type="range"
			name={name}
			min={min}
			max={max}
			value={text}
			onBlur={handleChange}
			onKeyUp={handleChange}
			onMouseUp={handleChange}
			onChange={handleChaos}
		/>
	);
}

export function Color({ onChange, value = '', ...props }: ColorProps): JSX.Element {
	const [color, setColor] = useState<string>(value);

	useEffect(() => {
		setColor(value);
	}, [value]);

	const handleChange = useCallback(
		(event) => {
			const { name, value } = event.target;
			setColor(value);
			if (typeof onChange === 'function') {
				// onChange(event);
				onChange({ [name]: value });
			}
		},
		[onChange],
	);
	const handleChaos = useCallback((event) => {
		setColor(event.target.value);
	}, []);

	return <input {...props} type="color" value={color} onBlur={handleChange} onMouseUp={handleChange} onChange={handleChaos} />;
}

export function Checkbox({ checked = false, isBoolean = false, onChange, ...props }: CheckboxProps): JSX.Element {
	const [isChecked, setIsChecked] = useState<boolean>(checked);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const handleChange = useCallback(
		(event) => {
			const { checked, name, value } = event.target;
			setIsChecked(checked);
			if (typeof onChange === 'function') {
				const payload = {
					[name]: isBoolean ? checked : checked ? value : undefined,
				};
				onChange(payload);
			}
		},
		[isBoolean, onChange],
	);

	return <input {...props} className="form-check-input" type="checkbox" checked={isChecked} onChange={handleChange} />;
}

export function Select({ label = null, floating = true, value = '', children, onChange, ...props }: SelectProps): JSX.Element {
	// const inputRef = useRef<HTMLSelectElement>(null);
	const [selected, setSelected] = useState(value);

	useEffect(() => {
		setSelected(value);
	}, [value]);

	const handleChange = useCallback(
		(event) => {
			const { name, value } = event.target;
			setSelected(value);
			if (typeof onChange === 'function') {
				// onChange(event);
				onChange({ [name]: value });
			}
		},
		[onChange],
	);

	return (
		<div className={classcat([{ 'form-floating': floating }])}>
			<select {...props} className="form-select" value={selected} onChange={handleChange}>
				{children}
			</select>
			{label !== null ? <label>{label}</label> : null}
		</div>
	);
}

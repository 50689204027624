import { useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paginate from 'react-paginate';
import ReactTimeAgo from 'react-time-ago';

import { useChannel } from 'components/Channel/Channel';

import './MusicID.scss';

export function MusicIDHistory(): JSX.Element {
	const [history, setHistory] = useState<MusicIDResults>(null);
	const { t, i18n } = useTranslation();
	i18n.loadNamespaces([`musicid`]);
	const { api } = useChannel();

	useLayoutEffect(() => {
		const load = async () => {
			try {
				const data = await api(`musicid`);
				setHistory(data);
			} catch (error) {
				console.error(error);
			}
		};
		if (history === null) {
			load();
		}
	}, [api, history]);

	const handlePageClick = useCallback(
		async ({ selected }) => {
			try {
				const data = await api(`musicid`, { page: selected });
				setHistory(data);
			} catch (error) {
				console.error(error);
			}
		},
		[api],
	);

	return (
		<>
			<div className="card">
				<div className="card-header">{t(`musicid:title`)}</div>
				<div className="card-body">
					{history?.results?.length ? (
						<>
							<table className="musicid-results">
								<tbody>
									{history.results.map((song) => {
										const { created_at, results } = song;
										const [match] = results;
										const timestamp = new Date(created_at);
										return (
											<tr key={song.id} className="musicid-result">
												<td className="result-time">
													<ReactTimeAgo date={timestamp} locale={`en`} timeStyle={`round-minute`} />
												</td>
												<td className="result-title">{match.title}</td>
												<td className="result-artist">{match.artist}</td>
												<td className="result-score" title={`Confidence`}>
													{match.score}%
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							{history.pages > 1 ? (
								<div>
									<Paginate
										className="pagination"
										nextLabel={t(`musicid:pagination.next`)}
										pageCount={history.pages}
										pageRangeDisplayed={5}
										previousLabel={t(`musicid:pagination.previous`)}
										onPageChange={handlePageClick}
									/>
								</div>
							) : null}
						</>
					) : (
						<>
							<div>{history === null ? 'Loading...' : 'No results. Big sad :('}</div>
						</>
					)}
				</div>
			</div>
			<div className="acr-attribution">
				<span>
					<a href="https://www.acrcloud.com" target="_blank" rel="noopener noreferrer">
						Music Recognition
					</a>{' '}
					by
				</span>{' '}
				<span>
					<a href="https://www.acrcloud.com" target="_blank" rel="noopener noreferrer">
						<img src="/images/ACRCloud.png" alt="ACRCloud" title="ACRCloud" />
					</a>
				</span>
			</div>
		</>
	);
}

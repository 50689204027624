import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'helpers/api';

import { selectOverlay } from './selectors';
// import { selectOverlays } from 'components/Dashboard/Eggs';

export const overlayLoad2 = createAction<{ id: string }>(`overlay/loader/pending`);
export const overlayLoaded = createAction<OverlayState>(`overlay/loader/fulfilled`);

// export const overlayLoad = createAsyncThunk(`overlay/load`, async (payload: { id: string }, { getState }): Promise<OverlayState> => {
export const overlayLoad = createAsyncThunk(`overlay/load`, async (payload: { id: string }): Promise<OverlayState> => {
	// const overlays = selectOverlays(getState());
	// console.debug(overlays);
	// if (overlays?.[id]) {
	// 	return overlays[id];
	// }
	const data: OverlayState = await API.get(`/overlays/${payload.id}`);
	return data;
});

export const overlayMetadata = createAction<OverlayMetadata>(`overlay/metadata`);

export const overlaySave = createAsyncThunk(`overlay/save`, async (_args: void, { getState }): Promise<any> => {
	const overlay = selectOverlay(getState());
	// return await API.put(`/users/me/overlays/${overlay.id}`, { ...overlay });
	return await API.put(`/overlays/${overlay.id}`, { ...overlay });
});

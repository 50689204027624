import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import { useAppSelector } from 'services/store';

import { selectUserProfile } from 'components/User/Egg/selectors';

import { StremmerAvatar } from 'components/Channel';

import './scss/Header.scss';
import { selectSession } from 'services/store/Eggs/Session';

export function MainHeader(): JSX.Element {
	return (
		<>
			<header id="stremmer-header" role="navigation">
				<Link to="/" className="logo stremmer-text">
					Stremmer
				</Link>
				<div className="header-item">
					<UserHeader />
				</div>
			</header>
		</>
	);
}

export function UserHeader(): JSX.Element {
	const { t } = useTranslation();
	const [displayName, setDisplayName] = useState<string>(null);
	const profile = useAppSelector(selectUserProfile);
	const session = useAppSelector(selectSession);

	useLayoutEffect(() => {
		try {
			if (profile) {
				setDisplayName(profile?.profile?.display_name ?? profile?.username);
			}
		} catch (error) {}
	}, [profile]);

	return session.validated && profile ? (
		<>
			<Link to="/dashboard">
				<div className="stremmer-header">
					<span className="stremmer-text">{t(`common:header.hello`, { displayName })}</span>
					<StremmerAvatar account={profile} />
				</div>
			</Link>
		</>
	) : (
		<>
			<NavLink to="/login" className="stremmer-text">
				{t(`common:action.login`)}
			</NavLink>
		</>
	);
}

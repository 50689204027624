import { createLogger } from 'redux-logger';
import type { Extension } from '@redux-eggs/core';

export const getLoggerExtension = (): Extension => {
	const middlewares = [];
	if (process.env.NODE_ENV !== 'production') {
		middlewares.push(
			createLogger({
				collapsed: true,
				colors: {
					title: () => '#F54897',
					prevState: () => '#BF4942',
					action: () => '#FFBD2E',
					nextState: () => '#28C941',
				},
				diff: true,
				duration: true,
				level: 'debug',
				timestamp: true,
				// predicate: (_, action) => [setScale.action.type].includes(action.type) === false,
			}),
		);
	}
	return {
		middlewares,
	};
};

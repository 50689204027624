import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const StremmerChannel = lazy(() => import('./Channel'));

export default function StremmerChannelRoutes(): JSX.Element {
	return (
		<>
			<Routes>
				<Route path="/:username" element={<StremmerChannel />} />
				<Route index element={<Navigate to="/" />} />
			</Routes>
		</>
	);
}

export * from './Avatar';
export * from './Channel';
export * from './Profile';

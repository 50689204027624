import { createStore } from '@redux-eggs/redux-toolkit';
import { getSagaExtension } from '@redux-eggs/saga-extension';

// import type { Action, AnyAction, PayloadAction, Store, ThunkDispatch } from '@reduxjs/toolkit';
import type { AnyAction, Store, ThunkDispatch } from '@reduxjs/toolkit';
import type { Extension } from '@redux-eggs/core';

import { getThunkExtension } from '../extensions/thunk.extension';
import { getLoggerExtension } from '../extensions/logger.extension';
import { getStremmerExtension } from '../extensions/stremmer.extension';

import { sessionStorageSave } from './browserStorage';
import { localStorageSave } from './browserStorage';
import { getUserEgg } from 'components/User/Egg';
import { getRouterEgg } from '../Eggs/Router';
import { getSessionEgg } from '../Eggs/Session';

export type ConfigureStoreOptions = {
	name: string;
	extensions?: Extension<any>[];
};

export function configureStore({ name }: ConfigureStoreOptions) {
	const store = createStore<Store & { dispatch: ThunkDispatch<any, unknown, AnyAction> }>({
		devTools: process.env.NODE_ENV !== 'production' ? true : false,
		// extensions: [...extensions, getSagaExtension(), getThunkExtension(), getStremmerExtension(), getLoggerExtension()],
		extensions: [getThunkExtension(), getSagaExtension(), getStremmerExtension(), getLoggerExtension()],
		defaultMiddlewareOptions: { serializableCheck: false },
	});
	store.subscribe(() => {
		const { router, session, ...state } = store.getState();
		localStorageSave(name, { ...state });
		sessionStorageSave(name, { router, session });
	});
	store.addEggs([getRouterEgg(), getSessionEgg(), getUserEgg()]);
	return store;
}

import { createSlice, nanoid } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'redux-first-history';

import type { AppState } from 'services/store';

// import { localStorageLoad } from 'services/store/utils/browserStorage';
import { sessionStorageLoad } from 'services/store/utils/browserStorage';

import { userLogout, userProfile, userValidated } from 'components/User/Egg/actions';

import { authResponse } from '../Router';

import { loaderClose, loaderOpen } from './actions';
import { initialSessionState } from './state';

export const sessionSlice = createSlice<SessionState, AppState>({
	name: 'session',
	initialState: (): SessionState => {
		// const state = localStorageLoad('stremmer');
		const state = sessionStorageLoad('stremmer');
		return state?.session ? state.session : { ...initialSessionState };
	},
	reducers: {},
	extraReducers(builder) {
		builder.addCase(LOCATION_CHANGE, (state) => {
			state.loader.active = false;
			// if (['PUSH', 'REPLACE'].includes(payload.action) === true) {
			// }
		});
		builder.addCase(loaderClose, (state) => {
			state.loader = { active: false, title: null };
		});
		builder.addCase(loaderOpen, (state, { payload }) => {
			state.loader = { active: true, title: payload };
		});

		builder.addCase(authResponse, (state, { payload }) => {
			if (payload.token) {
				state.token = payload.token;
			}
		});
		builder.addCase(userValidated, (state, { payload }: any) => {
			const { token, validated = false } = payload;
			state.validated = Boolean(validated);
			state.token = token;
		});
		builder.addCase(userLogout, () => {
			return { ...initialSessionState };
		});
		// builder.addCase(userLogout.fulfilled, () => {
		// 	return { ...initialSessionState };
		// });

		builder
			.addCase(userProfile.pending, (state) => {
				state.loader.active = true;
			})
			.addCase(userProfile.rejected, (state) => {
				state.loader = { active: false, title: null };
			})
			.addCase(userProfile.fulfilled, (state) => {
				state.loader = { active: false, title: null };
			});
	},
});

import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { Extension } from '@redux-eggs/core';

export const middlewareListener = createListenerMiddleware();

export const getStremmerExtension = (): Extension => {
	const middlewares = [middlewareListener.middleware];
	return {
		// enhancers: [],
		middlewares,
	};
};

import SimplexNoise from 'simplex-noise';

type CanvasConfig = {
	alpha?: number;
	background?: string;
	base?: number;
	divider?: number;
	//? divider: Number(Math.random()+1).toFixed(2),
	particles?: number;
	reset?: number;
	step?: number;
	width?: number;
	zIncrement?: number;
};

type CanvasSize = {
	height: number;
	width: number;
};

// const screen: CanvasSize = { height: 1080, width: 1920 };

export class BackgroundCanvas {
	// private static _particles: Set<Particle>;
	private _canvas: OffscreenCanvas | HTMLCanvasElement;
	private _config: CanvasConfig;
	private _context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D;
	// private _target: HTMLCanvasElement;
	// private _output: CanvasRenderingContext2D;
	// private _stream: any;

	private _screen: CanvasSize;
	private _particles: Set<Particle>;

	private _stopped = false;
	private _interval: ReturnType<typeof setInterval>;

	private _noise = { fallout: 0.5, fraction: 0.5, octaves: 4 };
	private _variables = { hueBase: 0, zOffset: 0 };

	private _simplexNoise: SimplexNoise = new SimplexNoise();

	constructor(target: HTMLCanvasElement, screen: CanvasSize = { height: 720, width: 1280 }, config: CanvasConfig = {}) {
		try {
			if ('transferControlToOffscreen' in target) {
				this._canvas = target.transferControlToOffscreen();
			} else {
				this._canvas = target;
			}
			if (!this._context) {
				this._context = this._canvas.getContext('2d');
				// this._context = this._canvas.getContext('2d', { desynchronized: true });
			}
			this._screen = screen;
			this._canvas.height = this._screen.height;
			this._canvas.width = this._screen.width;
			this._config = {
				alpha: 0.1,
				background: '#19191A',
				base: 1000,
				divider: 1.75,
				// divider: Number(Math.random()+1).toFixed(2),
				particles: 250,
				reset: 5,
				step: 5,
				width: 0.1,
				zIncrement: 0.002,
				...config,
			};
		} catch (error) {
			console.error(error);
		}
	}

	// static particles(amount: number, screen: CanvasSize) {
	// 	const particles = [...Array(amount)].map(() => {
	// 		return new Particle(0, screen);
	// 	});
	// 	this._particles = new Set(particles);
	// }

	animate() {
		try {
			// this._noise.octaves = this.getInteger(2,8)
			this._context.fillStyle = this._config.background;
			this._context.globalAlpha = 1;
			// this._context.imageSmoothingEnabled = true;
			// this._context.imageSmoothingQuality = 'high';
			this._context.lineCap = this._context.lineJoin = 'round';
			this._context.lineWidth = this._config.width;
			this._context.save();

			const particles = [...Array(this._config.particles)].map(() => {
				return new Particle(this._variables.hueBase, this._screen);
			});
			this._particles = new Set(particles);
			this._interval = setInterval(() => this.reset(), this._config.reset * 1000);
			setTimeout(() => {
				this._stopped = true;
			}, 30 * 1000);
			// this.update();
			requestAnimationFrame(() => this.update());
		} catch (error) {
			console.error(error);
		}
	}

	update() {
		this._particles.forEach((p) => {
			try {
				const divider = this._config.base * this._config.divider;
				const angle = Math.PI * 6 * this.getNoise(p.x / divider, p.y / divider, this._variables.zOffset);
				p.x += Math.cos(angle) * this._config.step;
				p.y += Math.sin(angle) * this._config.step;
				p.color.a += p.color.a < 0.8 ? 0.005 : 0;
				this._context.beginPath();
				this._context.strokeStyle = p.color.toString();
				this._context.moveTo(p.x, p.y);
				this._context.lineTo(p.pastX, p.pastY);
				this._context.stroke();

				if (0 > p.x !== p.x > this._screen.width || 0 > p.y !== p.y > this._screen.height) {
					if (this._stopped === true) {
						this._particles.delete(p);
						return;
					}
					p.initialise(this._variables.hueBase);
				}
			} catch (error) {
				console.error(error);
			}
		});
		this._variables.hueBase += 0.1;
		this._variables.zOffset += this._config.zIncrement;
		if (this._particles.size > 0) {
			requestAnimationFrame(() => this.update());
		}
	}

	getNoise(x: number, y: number, z: number) {
		let amp = 1,
			sum = 0,
			f = 1;
		for (let i = 0; i < this._noise.octaves; ++i) {
			amp *= this._noise.fallout;
			sum += amp * (this._simplexNoise.noise3D(x * f, y * f, z * f) + 1) * this._noise.fraction;
			f *= 2;
		}
		return sum;
	}
	private reset(): void {
		if (this._stopped === true && this._particles.size === 0) {
			clearInterval(this._interval);
			return;
		}
		const image = this._context.getImageData(0, 0, this._screen.width, this._screen.height);
		this._context.save();
		this._context.globalAlpha = 0.05;
		this._context.clearRect(0, 0, this._screen.width, this._screen.height);
		this._context.putImageData(image, 0, 0);
		// this._context.fillRect(0, 0, this._screen.width, this._screen.height);
		this._context.restore();
	}
	// initParticle(p: Particle) {
	// 	p.x = p.pastX = this._screen.width * Math.random();
	// 	p.y = p.pastY = this._screen.height * Math.random();
	// 	p.color.h = this._variables.hueBase + (Math.atan2(this._screen.height - p.y, this._screen.width - p.x) * 180) / Math.PI;
	// 	p.color.s = 1;
	// 	p.color.l = 0.5;
	// 	p.color.a = 0;
	// }
}

export class HSLA {
	public h = 0;
	public s = 0;
	public l = 0;
	public a = 0;

	toString() {
		return `hsla(${this.h}, ${this.s * 100}%, ${this.l * 100}%, ${this.a})`;
	}
}

export class Particle {
	private _x: number;
	private _y: number;
	public color: any;
	public pastX: number;
	public pastY: number;

	private _screen: CanvasSize = { height: 720, width: 1280 };

	get x() {
		return this._x;
	}
	set x(value: number) {
		this.pastX = this._x;
		this._x = value;
	}
	get y() {
		return this._y;
	}
	set y(value: number) {
		this.pastY = this._y;
		this._y = value;
	}

	constructor(hue = 0, screen: CanvasSize = { height: 720, width: 1280 }) {
		// constructor(hue = 0, x = 0, y = 0) {
		this.color = new HSLA();
		this._x = this.pastX = 0;
		this._y = this.pastY = 0;
		this._screen = screen;
		this.initialise(hue);
	}

	initialise(hue = 0) {
		const { height, width } = this._screen;
		this._x = this.pastX = width * Math.random();
		this._y = this.pastY = height * Math.random();
		this.color.h = hue + (Math.atan2(height / 2 - this._y, width / 2 - this._x) * 180) / Math.PI;
		this.color.s = 1;
		this.color.l = 0.5;
		this.color.a = 0;
	}
}

import { createContext, useContext } from 'react';
import { useAppSelector } from 'services/store';

const EventsContext = createContext<{ event: string; module?: string; selected?: string }>(null);

export function useEventsContext() {
	return useContext(EventsContext);
}

export function EventsProvider({ children, event, id }) {
	const selected = useAppSelector((state) => state.events?.selected) ?? null;
	return (
		<>
			<EventsContext.Provider value={{ event, module: id, selected }}>{children}</EventsContext.Provider>
		</>
	);
}

import type { Egg } from '@redux-eggs/core';
import type { AppStore } from 'services/store';

import { sessionSlice } from './slice';

export function getSessionEgg(): Egg<AppStore> {
	return {
		id: 'session',
		keep: true,
		reducersMap: {
			session: sessionSlice.reducer,
		},
	};
}

export * from './actions';
export * from './selectors';

// import { createSelector } from '@reduxjs/toolkit';

import { AppState, composeSelector } from 'services/store';

export const selectFonts = (state: AppState): OverlayFontsState => state.fonts;

export const selectFontsList: (state: AppState) => Pick<FontItem, 'family'>[] = composeSelector(
	(state: AppState): FontItem[] => selectFonts(state)?.items,
	(fonts: FontItem[]) => fonts.map(({ family }) => ({ family })),
);

export const initialEventsState: OverlayEventsState = {
	history: {},
	module: {},
	modules: {},
	sources: {},
	variables: {},
	variations: {},
};

// export const initialEventModuleState: OverlayEventModule = {
// 	event: {},
// 	variables: {},
// };

export const initialEventSourceState: OverlayEventSource = {
	id: '',
};

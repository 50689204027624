import { useEffect, useRef } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { store, useAppDispatch, useAppSelector } from 'services/store';
import { getCommonEggs, getOverlayEgg } from './Eggs';

import { overlayLoad, selectMetadata, selectOverlay } from './Eggs/Overlay';
import { selectLayers } from './Eggs/selectors';

import { OverlayModule } from './Modules';
import './scss/Overlays.scss';

export function BrowserSourceRoute(): JSX.Element {
	useEffect(() => {
		store.addEggs([...getCommonEggs(), getOverlayEgg()]);
	}, []);

	return (
		<>
			<Routes>
				<Route path=":overlay_id" element={<BrowserSource />} />
			</Routes>
		</>
	);
}

function BrowserSource(): JSX.Element {
	const dispatch = useAppDispatch();
	const overlayRef = useRef(null);
	const params = useParams();
	const overlay = useAppSelector(selectOverlay);
	const metadata = useAppSelector(selectMetadata);
	const layers = useAppSelector(selectLayers);

	useEffect(() => {
		if (params.overlay_id && !overlay) {
			dispatch(overlayLoad({ id: params.overlay_id }));
		}
	}, [dispatch, overlay, params]);

	useEffect(() => {
		if (metadata && overlayRef.current) {
			overlayRef.current.style.height = `${metadata.height}px`;
			overlayRef.current.style.width = `${metadata.width}px`;
		}
	}, [metadata]);

	return layers ? (
		<div ref={overlayRef}>
			{[...(layers || [])]
				.filter(({ status }) => status?.hidden !== true)
				.map(({ id }) => (
					<OverlayModule key={id} id={id} />
				))}
		</div>
	) : null;
}

import { eventSource } from 'components/Events';
import { API } from 'helpers/api';
import { actionChannel, call, put, take } from 'redux-saga/effects';
import { overlayLoad2, overlayLoaded } from './actions';

export function* overlayRootSagas() {
	const overlayChannel = yield actionChannel([overlayLoad2]);
	while (true) {
		try {
			const { payload } = yield take(overlayChannel);
			const overlay = yield call([API, API.get], `overlays/${payload.id}`);
			yield put(overlayLoaded(overlay));
		} catch (error) {
			console.error(error);
		}
	}
}

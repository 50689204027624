import classNames from 'classcat';
import { Suspense, useLayoutEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import type { PropsWithChildren } from 'react';

import { store, useAppSelector } from 'services/store';

import { selectLayerBounds, selectModuleMetadata } from '../Eggs/selectors';
import { importModuleComponent } from './Importer';

import { getModuleEgg } from '../Eggs/Module';
import EmptyModule from './Empty';

import '../scss/Overlays.scss';

type OverlayModuleProps = PropsWithChildren<{
	id: string;
	className?: string;
	onClick?: (event: any) => void;
}>;

export function OverlayModule({ id, className, onClick }: OverlayModuleProps): JSX.Element {
	// const dispatch = useAppDispatch();
	const [content, setContent] = useState(null);

	useLayoutEffect(() => {
		return store.addEggs([getModuleEgg(id)]);
	}, [id]);

	const bounds = useAppSelector((state) => selectLayerBounds(state, id));
	const metadata = useAppSelector((state) => selectModuleMetadata(state, id));

	useLayoutEffect(() => {
		const loadModule = async (type?: string) => {
			try {
				const component = await importModuleComponent(type, `Display`);
				setContent({ component });
			} catch (error) {
				console.error(error);
			}
		};
		if (metadata?.type && !content) {
			loadModule(metadata.type);
		}
	}, [content, id, metadata]);

	const { opacity, zIndex } = bounds;
	const { height, rotate = 0, width, left = 10, top = 10 } = bounds;
	const Component = content?.component ? content.component : EmptyModule;

	return (
		<>
			<div
				id={id}
				className={classNames(['overlay-layer', className])}
				style={{ top, left, height, width, opacity, zIndex, transform: `rotate(${rotate}deg)` }}
				title={metadata.title}
				onClick={onClick}
			>
				<Suspense fallback={<></>}>
					<ErrorBoundary fallback={<>Guru Meditation</>}>
						<div className={classNames('overlay-module')}>
							<Component id={id} />
						</div>
					</ErrorBoundary>
				</Suspense>
			</div>
		</>
	);
}

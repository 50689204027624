import classNames from 'classcat';
import { useLayoutEffect, useRef } from 'react';

import { selectSession } from 'services/store/Eggs/Session/selectors';
import { useAppSelector } from 'services/store';
import { BackgroundCanvas } from './canvas';

import './scss/Ambient.scss';

export const screen = { height: 720, width: 1280 };

export function AmbientBackground(): JSX.Element {
	const canvas = useRef<HTMLCanvasElement>(null);
	const session = useAppSelector(selectSession);

	useLayoutEffect(() => {
		if (canvas.current !== null) {
			const ambient = new BackgroundCanvas(canvas.current, screen);
			ambient.animate();
		}
	}, []);

	return (
		<>
			<div id="background" className={classNames({ loader: session?.loader.active })}>
				<div className="background-content">
					<div className="spinner">
						<div className="spinner-border text-primary"></div>
						<div>{session?.loader.title || ``}</div>
					</div>
				</div>
				<canvas id="ambient" ref={canvas}></canvas>
			</div>
		</>
	);
}

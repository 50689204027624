import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'helpers/api';
import { nanoid } from 'nanoid';

export const assetUpload = createAction(`@@assets/upload`, (file: File) => {
	return {
		meta: { uploadId: nanoid() },
		payload: { file },
	};
});

export const assetDelete = createAction<{ id: string }>(`@@assets/delete`);
export const assetDeleted = createAction<{ id: string }>(`@@assets/delete/fulfilled`);

export const assetsFetch = createAction<{ assets: string[] }>('@@assets/fetch');

export const assetLoaded = createAction('@@assets/loaded', (args: OverlayAsset & { uploadId?: string }) => {
	const { uploadId, ...payload } = args;
	return {
		meta: { uploadId },
		payload: { ...payload },
	};
});

/* -------------------------------------------------- */

export const assetList = createAsyncThunk('@@assets/list', async () => {
	// const data: AssetState[] = await API.get('/assets');
	const data: Record<string, OverlayAsset> = await API.get('/assets');
	return data;
});

// import reduceReducers from 'reduce-reducers';
import { createSelectors, undox } from 'undox';

import type { Egg } from '@redux-eggs/core';
import type { AppStore } from 'services/store';

import { overlayLoad, overlayLoad2, overlayReducer } from './Overlay';

import { getAssetsEgg } from 'components/Assets/Egg';
import { getEventsEgg } from 'components/Events';
import { getFontsEgg } from './Fonts';
import { overlayRootSagas } from './Overlay/sagas';

export function getCommonEggs() {
	return [getAssetsEgg(), getEventsEgg(), getFontsEgg()];
}

export function getOverlayEgg(id?: string, undoable = false): Egg<AppStore> {
	const reducer = undoable === true ? undox(overlayReducer) : overlayReducer;

	return {
		id: 'overlay',
		reducersMap: {
			overlay: reducer,
		},
		sagas: [overlayRootSagas],
		afterAdd(store) {
			if (id) {
				store.dispatch(overlayLoad2({ id }));
				// store.dispatch(overlayLoad({ id }));
			}
		},
	};
}

export const { getPresentState } = createSelectors(overlayReducer);

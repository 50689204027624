import { createReducer } from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';

import { localStorageLoad } from 'services/store';
import { fontsFetch, fontsLoaded } from './actions';

const initialFontsState: OverlayFontsState = {
	items: [],
	active: [],
};

export const fontsReducer = createReducer(
	(): OverlayFontsState => {
		const state = localStorageLoad(`stremmer`);
		return state?.fonts ? { ...state.fonts, active: [] } : { ...initialFontsState };
	},
	(builder) => {
		builder.addCase(fontsFetch.fulfilled, (state, { payload }) => {
			state.items = [...payload];
		});
		builder.addCase(fontsLoaded, (state, { payload }) => {
			const set = new Set([...state.active, ...payload.fonts]);
			state.active = Array.from(set.values());
			// state.active = [...state.active, ...payload.fonts];
		});
	},
);

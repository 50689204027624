import type { Egg } from '@redux-eggs/core';
import type { AppStore } from 'services/store';

// import { fontsFetch } from './actions';
import { fontsReducer } from './reducers';
import { fontSagas } from './sagas';
// import { fontSlice } from './slice';

export const getFontsEgg = (): Egg<AppStore> => {
	return {
		id: 'fonts',
		keep: true,
		reducersMap: {
			fonts: fontsReducer,
			// fonts: fontSlice.reducer,
		},
		sagas: [fontSagas],
		// afterAdd(store) {
		// 	const state = store.getState();
		// 	if (state.fonts.items.length === 0) {
		// 		store.dispatch(fontsFetch());
		// 	}
		// },
	};
};

import { createSlice } from '@reduxjs/toolkit';
import { userChannels } from 'components/User/Egg/actions';

export const channelsSlice = createSlice({
	name: 'channels',
	initialState: {
		loading: false,
		channels: [],
	},
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(userChannels.fulfilled, (state, { payload }) => {
				state.channels = [...payload];
			})
			.addCase(userChannels.rejected, (state) => {
				state.channels = [];
			});
		// builder
		// 	.addCase(channelsFetch.fulfilled, (state, { payload }) => {
		// 		state.channels = [...payload];
		// 	})
		// 	.addCase(channelsFetch.rejected, (state) => {
		// 		state.channels = [];
		// 	});
	},
});

import { nanoid, PayloadAction } from '@reduxjs/toolkit';
import { LOCATION_CHANGE, replace } from 'redux-first-history';
import { matchPath } from 'react-router-dom';
import { actionChannel, call, put, take, takeEvery, takeLeading } from 'redux-saga/effects';

import type { RouterState } from 'redux-first-history';

import { userAccounts, userLogout, userValidated } from 'components/User/Egg/actions';
import { authResponse } from './actions';
import { API } from 'helpers/api';

export function* routerSaga() {
	// yield spawn(authCallbackWorker);
	// yield spawn(authResponseWorker);

	yield takeEvery(userLogout, userLogoutHandler);
	yield takeLeading(LOCATION_CHANGE, userValidateHandler);
}

// const authRegex = new RegExp(/^\/auth\/\w+?/i);

export function* userLogoutHandler() {
	try {
		yield call([API, API.post], `/auth/logout`);
	} catch (error) {
		console.error(error);
	} finally {
		yield put(userValidated({ validated: false }));
		yield put(replace('/login'));
	}
}

function* userValidateHandler(data: PayloadAction<RouterState>) {
	let redirectTo: string = null;
	try {
		const { action, location } = data.payload;
		const match = matchPath(`/auth/:provider/callback`, location?.pathname);
		if (action === 'POP' && match !== null && location?.search) {
			const { pathname, search } = location;
			const data = yield call([API, API.get], pathname, { params: new URLSearchParams(search) });
			switch (data?.action?.type) {
				case 'connect':
				case 'reconnect': {
					redirectTo = `/dashboard/accounts`;
					break;
				}
				default: {
					redirectTo = `/dashboard`;
				}
			}
		}
		if (action === 'POP' && match === null) {
			yield call([API, API.post], `/auth/validate`);
		}
		const accounts = yield call([API, API.get], `/users/me/accounts`);
		yield put(userValidated({ token: nanoid(), validated: true }));
		yield put(userAccounts(accounts));
	} catch (error) {
		const data = error?.toJSON();
		if (data?.status === 401) {
			yield put(userLogout());
		}
	} finally {
		if (redirectTo !== null) {
			yield put(replace(redirectTo));
		}
	}
}

export function* authCallbackWorker() {
	const channel = yield actionChannel(LOCATION_CHANGE);
	while (true) {
		try {
			const { payload }: { payload: RouterState } = yield take(channel);
			const match = matchPath('/auth/:provider/callback', payload.location?.pathname);
			if (match && payload.location?.search) {
				const { pathname, search } = payload.location;
				const data = yield call([API, API.get], pathname, { params: new URLSearchParams(search) });
				if (data.status > 299) {
					yield put(replace(`/login`));
					continue;
				}
				yield put(authResponse({ ...data }));
				// yield put(yield userAccounts());
			}
		} catch (error) {
			console.error(error);
		}
	}
}

export function* authResponseWorker() {
	const channel = yield actionChannel(authResponse);
	while (true) {
		try {
			const { payload } = yield take(channel);
			let to = `/dashboard`;
			switch (payload.action?.type) {
				case 'connect':
				case 'reconnect': {
					to = `/dashboard/accounts`;
					break;
				}
			}
			yield put(replace(to));
		} catch (error) {
			console.error(error);
			yield put(replace(`/dashboard`));
		}
	}
}

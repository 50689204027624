import type { Egg } from '@redux-eggs/core';
import type { AppStore } from 'services/store';

import { assetsReducer } from './reducers';
import { assetSagas } from './sagas';

export * from './actions';
export * from './selectors';
export * from './state';

export function getAssetsEgg(): Egg<AppStore> {
	return {
		id: 'assets',
		keep: true,
		sagas: [assetSagas],
		reducersMap: {
			assets: assetsReducer,
		},
	};
}

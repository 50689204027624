import { createSelector } from '@reduxjs/toolkit';
import { composeSelector } from 'services/store/utils/composeSelector';

import type { AppState } from 'services/store';

export const selectAssets = (state: AppState): OverlayAssetsState => state.assets;

export const selectAssetsUpload = (state: AppState): OverlayAsset[] => state.assets?.uploads2;

export const selectAssetsList: (state: AppState) => OverlayAsset[] = composeSelector(
	(state: AppState) => selectAssets(state)?.assets,
	(assets: Record<string, AssetState>) => Object.values({ ...(assets || {}) }),
);

export const selectAssetUploads: (state: AppState) => OverlayAssetUpload[] = composeSelector(
	(state: AppState) => selectAssets(state)?.uploads,
	(uploads: Record<string, AssetState>) => Object.values({ ...(uploads || {}) }),
);

export const selectAssetList: (state: AppState) => Record<string, OverlayAsset> = composeSelector(
	(state: AppState) => selectAssets(state)?.assets,
	(assets) => assets,
);

export const selectAsset: (state: AppState, id: string) => OverlayAsset = createSelector(
	(state: AppState, id: string) => selectAssetList(state)?.[id],
	(asset) => asset,
);

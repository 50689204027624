export const initialLayerState = (height: number, width: number, zIndex: number): OverlayLayer => {
	return {
		id: '',
		bounds: {
			left: width / 2 - 50,
			top: height / 2 - 50,
			height: 100,
			width: 100,
			opacity: 1,
			rotate: 0,
			zIndex,
		},
		status: {
			hidden: false,
			locked: false,
		},
	} as OverlayLayer;
};

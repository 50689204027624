import { Chance } from 'chance';
import { Engine } from 'json-rules-engine';
import { Channel, channel } from 'redux-saga';
import { actionChannel, call, delay, put, race, select, spawn, take, takeEvery } from 'redux-saga/effects';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { EngineResult, RuleProperties } from 'json-rules-engine';

import { getModuleOrVariant } from 'components/Overlays/Eggs/selectors';
import { moduleLoaded, moduleUpdate } from 'components/Overlays/Eggs/Module';

import { eventActive, eventCancel, eventMessage, eventQueued, eventSkip, eventsRegister, eventStatus } from '../actions';
import { priorityBuffer } from './buffer';
import { getEngineRules, getEventHistory, getEventModule } from '../selectors';

export function* eventModulesSaga() {
	yield takeEvery(moduleLoaded, eventModuleWorker);
}

function* eventModuleWorker({ payload }: PayloadAction<any>) {
	try {
		const { id } = payload;
		const eventsEngine = new Engine([], { allowUndefinedFacts: true });
		const eventsQueue = channel(priorityBuffer());

		yield spawn(eventRulesHandler, id, eventsEngine);
		yield takeEvery((action: any) => moduleUpdate.match(action) && action.payload.id === id, eventRulesHandler, id, eventsEngine);
		yield spawn(eventMessageSaga, eventsEngine, eventsQueue);
		yield spawn(eventQueueWorker, eventsQueue);
	} catch (error) {
		console.error(error);
	}
}

function* eventRulesHandler(id: string, eventsEngine: Engine) {
	try {
		const rules: RuleProperties[] = yield select(getEngineRules, id);
		if (rules.length >= 1) {
			for (const rule of rules) {
				eventsEngine.removeRule(rule.name);
				eventsEngine.addRule(rule);
			}
			yield put(eventsRegister({ id, rules }));
		}
	} catch (error) {
		console.error(error);
	}
}

function* eventMessageSaga(eventsEngine: Engine, eventsQueue: Channel<any>) {
	const eventMessageChannel = yield actionChannel(eventMessage);
	while (true) {
		try {
			const { payload } = yield take(eventMessageChannel);
			const { data, id, type } = yield select(getEventHistory, payload.id);

			// Run the rules engine, and see where it's going
			const engine: EngineResult = yield call([eventsEngine, eventsEngine.run], { event: type, type, ...data.variables, ...data.flags });

			if (engine.events.length >= 1) {
				const chance = new Chance();
				const weights = engine.events.map(({ params }) => params.chance ?? 50);
				const event = chance.weighted(engine.events, weights);
				const { params } = event;
				const reply: OverlayEventQueued = { id: params.module, event: id, params, flags: { ...(data.flags || {}) } };

				yield put(eventsQueue, eventQueued({ ...reply }));
				// Is it an instant event?
				if (reply.flags?.instant == true) {
					yield put(eventSkip());
				}
			}
		} catch (error) {
			console.error(error);
		}
	}
}

function* eventQueueWorker(eventsQueue: Channel<any>) {
	while (true) {
		try {
			const { payload } = yield take(eventsQueue);
			yield call(eventHandler, payload);
		} catch (error) {
			console.error(error);
		}
	}
}

function* eventHandler(payload: OverlayEventQueued) {
	try {
		const previous = yield select(getEventModule, payload.params?.module);
		if (previous && previous.event === payload.event) {
			return;
		}
		const { module, variation } = payload.params;
		const content: OverlayModule = yield select(getModuleOrVariant, module, variation);
		yield put(eventActive({ ...payload }));
		const { duration = 0 } = content?.events || {};
		if (duration > 0) {
			yield put(eventStatus({ id: module, active: true }));
			yield race([delay(duration), call(isEventCancelled)]);
			yield put(eventStatus({ id: module, active: false }));
		}
	} catch (error) {
		console.error(error);
	}
}

/* -------------------------------------------------- */

function* isEventCancelled() {
	while (true) {
		try {
			const action = yield take([eventCancel, eventSkip]);
			if (eventCancel.match(action) === true) {
				// if (action.payload.id === id) {
				// 	return;
				// }
				return action;
			}
			if (eventSkip.match(action) === true) {
				return action;
				// continue;
			}
		} catch (error) {
			console.error(error);
		}
	}
}

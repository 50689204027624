import { createAction, createAsyncThunk, nanoid } from '@reduxjs/toolkit';

import { selectOverlay } from '../selectors';

export const moduleCopy = createAsyncThunk(`modules/copy`, async (source: OverlayModule['id'], { getState }): Promise<CreatedModulePayload> => {
	const { layers, modules } = selectOverlay(getState());

	const layer = layers[source];
	const module = Object.values({ ...modules }).find(({ id }) => id === source);

	const id = nanoid();
	const payload = {
		id,
		layer: { ...layer, id },
		module: { ...module, id },
	};
	return payload;
});

export const moduleLoaded = createAction<{ id: string }>(`module/loaded`);

export const moduleAdd = createAction<any>(`modules/add`);
export const moduleDelete = createAction<UpdatePayload>(`module/delete`);

export const moduleUpdate = createAction<UpdatePayload>(`module/update`);
export const modulePatch = createAction<{ id: string } & Record<string, any>>(`module/patch`);

export const moduleMetadata = createAction<{ id: string; metadata: OverlayModule['metadata'] }>(`module/metadata`);

export const moduleAssets = createAction<UpdatePayload>(`module/assets`);
export const moduleContent = createAction<UpdatePayload>(`module/content`);
export const moduleCSS = createAction<UpdatePayload>(`module/css`);

export const moduleVariables = createAction<{ id: string; event: string; variables: any }>(`module/variables`);

export const variationAdd = createAction<{ id: string; event: string }>(`module/variation/add`);
export const variationDelete = createAction<{ id: string; variation?: string }>(`module/variation/delete`);

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const layersActive = createAction<string[]>(`layers/active`);

export const layerBounds = createAsyncThunk(`layers/bounds`, async (payload: LayerUpdate | LayerUpdate[]): Promise<LayerUpdate[]> => {
	const data = Array.isArray(payload) === true ? payload : [payload];
	return data as LayerUpdate[];
});

export const layerSort = createAction<{ from: number; to: number }>(`layers/sort`);
export const layerStatus = createAction<LayerUpdate>(`layers/status`);

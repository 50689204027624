import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'helpers/api';

export const userLogin2 = createAction<void>(`@@auth/login`);
export const userLogout = createAction<void>(`@@auth/logout`);

export const userValidated = createAction<{ validated?: boolean; token?: string }>(`@@auth/validated`);

export const userAccounts = createAction<any[]>(`@@user/accounts`);

/* -------------------------------------------------- */

// export const userAccounts = createAsyncThunk(`user/accounts/fetch`, async (): Promise<any[]> => {
// 	return await API.get(`/users/me/accounts`);
// });

export const userChannels = createAsyncThunk(`user/channels/fetch`, async (): Promise<any[]> => {
	return await API.get(`/users/me/channels`);
});

export const userProfile = createAsyncThunk(`user/profile/fetch`, async (): Promise<any> => {
	return await API.get(`/users/me`);
});

// export const userLogin = createAsyncThunk(`user/login`, async (args: void, { dispatch }): Promise<any> => {
// 	const data = await API.post(`/auth/login`);
// 	dispatch(userAccounts());
// 	return data;
// });

// export const userLogout = createAsyncThunk(`user/logout`, async (args: void, { dispatch }): Promise<void> => {
// 	await API.post(`/auth/logout`);
// 	dispatch(replace(`/login`));
// 	return;
// });

// export const accountSettings = createAsyncThunk(`user/accounts/settings`, async (payload: any): Promise<any> => {
export const accountSettings = createAsyncThunk(`account/settings/channel`, async (payload: any): Promise<any> => {
	const { id, ...data } = payload;
	console.debug(payload);
	return await API.put(`/users/me/accounts/${id}/settings`, data);
});

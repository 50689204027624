// import { createSelector } from '@reduxjs/toolkit';

// import { replaceContentVariables } from 'utils';
import { composeSelector } from 'services/store/utils/composeSelector';
import type { AppState } from 'services/store';
import { createSelector } from '@reduxjs/toolkit';

const selectOverlay = (state: AppState): OverlayState => state.overlay?.present ?? state.overlay;

export const selectModules: (state: AppState) => OverlayState['modules'] = (state: AppState) => selectOverlay(state)?.modules;

export const selectModule: (state: AppState, id: string) => OverlayModule = composeSelector(
	(state: AppState, id: string) => selectOverlay(state)?.modules?.[id],
	(module: OverlayModule) => module,
);

/* -------------------------------------------------- */

export const getModuleOrVariant: (state: AppState, id: string, variation?: string) => OverlayModule = createSelector(
	(state: AppState, id: string) => selectOverlay(state)?.modules?.[id],
	(state: AppState, id: string, variation?: string) => variation,
	(module, variation) => {
		return module?.variations?.[variation] || module;
	},
);

/* -------------------------------------------------- */

export const selectModuleAssets: (state: AppState, id: string) => OverlayModule['assets'] = composeSelector(
	(state: AppState, id: string) => selectModule(state, id)?.assets,
	(assets) => assets,
);

export const selectModuleAsset: (state: AppState, id: string, asset: string) => OverlayModuleAsset = composeSelector(
	(state: AppState, id: string, key: string) => selectModule(state, id)?.assets?.[key],
	(asset: OverlayModuleAsset) => asset,
);

export const selectModuleContent: (state: AppState, id: string) => OverlayModule['content'] = composeSelector(
	(state: AppState, id: string) => selectModule(state, id)?.content,
	(data) => data,
);

export const selectModuleCSS: (state: AppState, id: string) => OverlayModule['css'] = composeSelector(
	(state: AppState, id: string) => selectModule(state, id)?.css,
	(data) => data,
);

export const selectModuleCustom: (state: AppState, id: string) => OverlayModule['custom'] = composeSelector(
	(state: AppState, id: string) => selectModule(state, id)?.custom,
	(data) => data,
);

export const selectModuleMetadata: (state: AppState, id: string) => OverlayModule['metadata'] = composeSelector(
	(state: AppState, id: string) => selectModule(state, id)?.metadata,
	(metadata) => metadata,
);

/* -------------------------------------------------- */

import type { Egg } from '@redux-eggs/core';
import type { AppStore } from 'services/store';

import { moduleLoaded } from './actions';
import { moduleSagas } from './sagas';

export * from './actions';
export * from './slice';

export function getModuleEgg(id: string): Egg<AppStore> {
	return {
		id: `module-${id}`,
		sagas: [moduleSagas(id)],
		afterAdd(store) {
			store.dispatch(moduleLoaded({ id }));
		},
	};
}

import { getInjector } from '@redux-eggs/react';
import { lazy, Suspense } from 'react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { Helmet, HelmetProps, HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import useAckee from 'use-ackee';

import { history, store, useAppSelector } from 'services/store';
import { selectSession } from 'services/store/Eggs/Session';

import { AmbientBackground } from 'components/Ambient';
import { MainContainer } from 'layouts/Main/Main';

import { StremmerLoader } from 'components/Ambient/Loader';

import { BrowserSourceRoute } from 'components/Overlays';

import 'services/translation';

import './Stremmer.scss';

const Injector = getInjector([]);

const Dashboard = lazy(() => import('pages/Dashboard'));
const Homepage = lazy(() => import('pages/Home'));

const OverlayEditor = lazy(() => import('components/Editor'));

const helmetConfig: HelmetProps = {
	defaultTitle: `Stremmer.`,
	defer: false,
	titleTemplate: `%s :: Stremmer.`,
};

export default function Stremmer(): JSX.Element {
	return (
		<>
			<HelmetProvider>
				<Helmet {...helmetConfig}></Helmet>
				<ReduxProvider store={store}>
					<Injector.Wrapper>
						<Router history={history}>
							<Suspense fallback={<StremmerLoader />}>
								<Routes>
									<Route path="/overlays/editor/:overlay_id" element={<ProtectedRoute children={<OverlayEditor />} />} />
									<Route path="/overlay/*" element={<BrowserSourceRoute />} />
									{/* <Route path="/overlay/:overlay_id" element={<BrowserSourceRoute />} /> */}
									<Route path="/*" element={<StremmerContainer />} />
								</Routes>
							</Suspense>
						</Router>
					</Injector.Wrapper>
				</ReduxProvider>
			</HelmetProvider>
		</>
	);
}

function StremmerContainer(): JSX.Element {
	const location = useLocation();
	useAckee(
		location.pathname,
		{
			server: 'https://stats.stremmer.dev',
			domainId: process.env.REACT_APP_ACKEE_DOMAIN_ID,
		},
		{
			detailed: true,
			ignoreLocalhost: true,
			ignoreOwnVisits: true,
		},
	);

	return (
		<MainContainer>
			<Routes>
				<Route path="/dashboard/*" element={<ProtectedRoute children={<Dashboard />} />} />
				<Route path="/*" element={<Homepage />} />
			</Routes>
			<AmbientBackground />
		</MainContainer>
	);
}

function ProtectedRoute({ children }): JSX.Element {
	// const user = useAppSelector(selectUser);
	// return user?.accounts?.length ? <>{children}</> : <Navigate to="/login" />;
	const session = useAppSelector(selectSession);
	return session.validated ? <>{children}</> : <Navigate to="/login" />;
}

import { useChannel } from './Channel';
import { StremmerAvatar } from './Avatar';
// import MusicIDHistory from 'components/Modules/MusicID/Music.History';

import { ReactComponent as TwitchPartner } from './twitch-partner.svg';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet-async';

export function StremmerProfile(): JSX.Element {
	const { channel } = useChannel();

	return (
		<>
			<Helmet title={channel?.profile?.display_name} />
			<div className="stremmer-profile">
				<div className="profile-image">
					<a href={channel.url} target="_blank">
						<StremmerAvatar account={channel} channel={channel} />
					</a>
				</div>
				<div className="profile-description">
					<h4>
						<a href={channel.url} className="stremmer-text" target="_blank" rel="noopener">
							{channel.profile.display_name}
						</a>
						{channel.profile.broadcaster_type === 'partner' ? <TwitchPartner title="Verified Partner" /> : null}
					</h4>
					<p>{channel.profile.description}</p>
				</div>
				<div className="profile-links">
					<ul>
						<li>
							<a className="brand-twitch" href={channel.url} target="_blank" rel="noopener">
								<i className="fab fa-twitch"></i>
							</a>
						</li>
					</ul>
				</div>
				{channel?.stream?.online ? (
					<div className="profile-video">
						<ReactPlayer height={`100%`} width={`100%`} url={channel.url} />
					</div>
				) : null}
			</div>
		</>
	);
}

import { createReducer, nanoid } from '@reduxjs/toolkit';
import { createSelectors } from 'undox';

import { localStorageLoad } from 'services/store';

import { overlayLoad, overlayLoaded, overlayMetadata } from './actions';
import { selectOverlay } from '../selectors';

import { initialLayerState, layersSlice } from '../Layer';

import { moduleAdd, moduleSlice } from '../Module';
import { moduleDelete } from '../Module';
import { initialModuleState } from '../Module/state';
import { initialOverlayState } from './state';

export const overlayReducer = createReducer(
	(): OverlayState => {
		const overlay = selectOverlay(localStorageLoad(`stremmer`));
		return { ...initialOverlayState(), ...(overlay || {}) };
	},
	(builder) => {
		builder.addCase(overlayLoaded, (_state, action) => {
			return { ...initialOverlayState(), ...action.payload };
		});
		builder.addCase(overlayLoad.fulfilled, (_state, action) => {
			return { ...initialOverlayState(), ...action.payload };
		});
		builder.addCase(overlayMetadata, (state, { payload }) => {
			state.metadata = { ...state.metadata, ...payload };
		});

		builder.addCase(moduleDelete, (state, { payload }) => {
			const { id } = payload;
			if (state.layers[id]) {
				delete state.layers[id];
			}
		});

		builder.addCase(moduleAdd, (state, { payload }) => {
			const id = nanoid();
			const length = Object.keys(state.layers).length;
			const title = `Layer ${length + 1}`;
			state.modules = {
				...state.modules,
				[id]: {
					...initialModuleState({ id, title, type: payload.type }),
					events: { ...payload.events },
					variations: { ...payload.variations },
				},
			};
			const { height, width } = state.metadata;
			const layer = { ...initialLayerState(height, width, length + 1), id };
			state.layers = {
				...state.layers,
				[id]: { ...layer, id },
			};
		});

		builder.addMatcher(
			(action) => action.type.startsWith(`layers/`),
			(state, action) => {
				state.layers = layersSlice.reducer(state.layers, action);
			},
		);

		builder.addMatcher(
			(action) => action.type.startsWith(`module/`),
			(state, action) => {
				const { id } = action.payload;
				if (state.modules[id]) {
					state.modules[id] = moduleSlice.reducer(state.modules[id], action);
				}
			},
		);
	},
);

export const undoxSelectors = createSelectors(overlayReducer);

import { Engine } from 'json-rules-engine';

import type { ConditionProperties } from 'json-rules-engine';

import eventTypes from './Events.json';

export function createEventEngine() {
	return new Engine([], { allowUndefinedFacts: true });
}

export const eventsEngine = new Engine([], { allowUndefinedFacts: true });

export function conditionItem(event: string, { fact, operator, value }: ConditionProperties) {
	if (!fact || !value) {
		return null;
	}
	const facts = eventTypes[event]?.facts as EventTypeDefinition['facts'];
	switch (facts[fact]) {
		case 'array': {
			value = value.split(',').map((item: string) => item.trim());
			break;
		}
		case 'boolean': {
			value = Boolean(value);
			break;
		}
		case 'number': {
			value = Number(value);
			break;
		}
	}
	return { fact, operator, value };
}

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
// import { selectModule } from 'components/Overlays/Eggs/selectors';
// import { getEventVariations, selectModuleVariations } from '../selectors';
import { selectModuleVariations } from '../selectors';

export const variationsLoad = createAsyncThunk(`@@variations/load`, async (payload: { event?: string; module: string }, { getState }) => {
	const { module } = payload;
	return selectModuleVariations(getState(), module);
	// const variants = getEventVariations(selectModule(getState(), module), event);
	// console.debug(`variants`, variants);
	// return variants;
});
export const variationsUnload = createAction(`@@variations/unload`);

export const variationsSelect = createAction<{ id: string }>(`@@variations/select`);

export const variationsAdd = createAction<any>(`@@variations/add`);
export const variationsDelete = createAction<{ id: string }>(`@@variations/delete`);
export const variationsUpdate = createAction<any>(`@@variations/update`);

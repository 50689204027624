import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

import { routerSaga } from './sagas';

import type { Egg } from '@redux-eggs/core';
import type { AppStore } from 'services/store';
// import { localStorageLoad } from 'services/store';

export const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
	// history: createBrowserHistory({ getUserConfirmation }),
	// reduxTravelling: true,
	history: createBrowserHistory(),
	routerReducerKey: 'router',
	savePreviousLocations: 5,
});

export * from './actions';

export function getRouterEgg(): Egg<AppStore> {
	return {
		id: 'router',
		keep: true,
		sagas: [routerSaga],
		middlewares: [routerMiddleware],
		reducersMap: {
			router: routerReducer,
		},
	};
}

import type { Egg } from '@redux-eggs/core';

import type { AppStore } from 'services/store';

// import { userSagas } from './sagas';
import { userSlice } from './slices';

export function getUserEgg(): Egg<AppStore> {
	return {
		id: 'user',
		keep: true,
		reducersMap: {
			user: userSlice.reducer,
		},
		// sagas: [userSagas],
	};
}

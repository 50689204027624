import { useLayoutEffect } from 'react';
import { loaderClose, loaderOpen } from 'services/store/Eggs/Session/actions';

import { useAppDispatch } from 'services/store';

export function StremmerLoader(): JSX.Element {
	const dispatch = useAppDispatch();

	useLayoutEffect(() => {
		dispatch(loaderOpen(`Loading...`));
		return () => {
			dispatch(loaderClose());
		};
	}, [dispatch]);

	return null;
}

import { createReducer } from '@reduxjs/toolkit';
import { setProperty } from 'dot-prop';

import { moduleDelete } from 'components/Overlays/Eggs/Module';

import { eventActive, eventsClear, eventSource, eventsRegister, eventStatus, eventVariables, eventMessage } from './actions';
import { variationsAdd, variationsDelete, variationsLoad, variationsSelect, variationsUnload, variationsUpdate } from './actions';

import { conditionsReducer } from './reducers/conditions';
import { initialEventsState } from './state';
import { createVariation } from './utils';

export const eventsReducer = createReducer(
	(): OverlayEventsState => {
		return { ...initialEventsState };
	},
	(builder) => {
		/* -------------------------------------------------- */

		builder.addCase(eventMessage, (state, { payload }) => {
			const { id } = payload;
			if (!state.history?.[id]) {
				state.history[id] = { ...payload };
			}
		});

		/* -------------------------------------------------- */

		builder.addCase(eventsClear, (state) => {
			Object.keys({ ...state.module }).forEach((id) => {
				state.module[id].active = false;
			});
		});

		builder.addCase(eventActive, (state, { payload }) => {
			const { id, event, params } = payload;
			if (state.module[id]) {
				state.module[id].id = id;
				// state.module[id].active = true;
				state.module[id].event = event;
				state.module[id].params = { ...params };
			}
		});

		builder.addCase(eventStatus, (state, { payload }) => {
			const { id, active = false } = payload;
			if (state.module[id]) {
				state.module[id].active = active;
			}
		});

		/* -------------------------------------------------- */

		builder.addCase(eventSource, (state, { payload }) => {
			const { id } = payload;
			state.sources = { ...(state.sources || {}), [id]: { id, history: {} } };
		});

		builder.addCase(eventsRegister, (state, { payload }) => {
			const { id } = payload;
			state.module[id] = { id, active: false, event: null, params: {}, type: null };
			// state.modules[id] = { id, event: {}, params: {}, variables: {}, ...(state.modules[id] || {}) };
		});

		/* -------------------------------------------------- */

		// builder.addCase(eventActive, (state, { payload }) => {
		// 	const { active = true, id, event, params, variables } = payload;
		// 	state.modules[id].event = {
		// 		...(state.modules[id].event || {}),
		// 		...(event || {}),
		// 		active,
		// 	};
		// 	// state.modules[id] = {
		// 	// 	...(state.modules[id] || {}),
		// 	// 	event: { ...event, active },
		// 	// 	// ...rest,
		// 	// };
		// 	state.modules[id].params = {
		// 		...(state.modules[id].params || {}),
		// 		...(params || {}),
		// 	};
		// 	state.modules[id].variables = {
		// 		...(state.modules[id].variables || {}),
		// 		...(variables || {}),
		// 	};
		// });

		builder.addCase(eventVariables, (state, { payload }) => {
			const { event, variables } = payload;
			const { id, timestamp, type } = event;
			state.variables[event.type] = { ...variables };
			state.history[id] = { id, timestamp, type, data: variables };
		});

		/* -------------------------------------------------- */

		builder.addCase(moduleDelete, (state, { payload }) => {
			const { id } = payload;
			// if (state.modules[id]) {
			// 	delete state.modules[id];
			// }
			if (state.module[id]) {
				delete state.module[id];
			}
		});

		/* -------------------------------------------------- */

		builder.addCase(variationsLoad.fulfilled, (state, { payload }) => {
			state.variations = { ...payload };
		});

		builder.addCase(variationsUnload, (state) => {
			state.selected = null;
			state.variations = {};
		});

		builder.addCase(variationsSelect, (state, { payload }) => {
			state.selected = payload.id;
		});

		/* -------------------------------------------------- */

		builder.addCase(variationsAdd, (state, { payload }) => {
			const { event } = payload;
			const variation = createVariation(event);
			state.variations = { ...state.variations, ...variation };
		});

		builder.addCase(variationsDelete, (state, { payload }) => {
			const { id } = payload;
			delete state.variations[id];
			if (state.selected === id) {
				state.selected = Object.keys(state.variations)[0];
			}
		});

		builder.addCase(variationsUpdate, (state, { payload }) => {
			const { id, ...update } = payload;
			Object.entries({ ...(update || {}) }).forEach(([path, value]) => {
				setProperty(state.variations[id], path, value);
			});
			// state.variations[id] = { ...state.variations[id], ...payload };
		});

		/* -------------------------------------------------- */

		/* -------------------------------------------------- */

		// -----

		builder.addMatcher(
			(action) => action.type.startsWith(`@@conditions/`),
			(state, action) => {
				state.variations[state.selected].events = conditionsReducer(state.variations[state.selected].events, action);
			},
		);
	},
);

// Applied on state.xxx.module.events
// export const eventReducer = createReducer(
// 	(): OverlayEventModule => {
// 		// const init = {
// 		// 	events: {},
// 		// };
// 		return { ...initialEventModuleState };
// 	},
// 	(builder) => {
// 		builder.addCase(moduleLoaded, (state, { payload }) => {
// 			return { ...state, id: payload.id, event: {}, variables: {} };
// 		});
// 		builder.addCase(eventActive, (state, { payload }) => {
// 			const { active = true } = payload;
// 			state.event = { ...state.event, active };
// 		});
// 		builder.addCase(eventVariables, (state, { payload }) => {
// 			const { event, variables } = payload;
// 			state.event = { ...state.event, ...(event || {}) };
// 			state.variables = { ...state.variables, [event.type]: { ...(variables || {}) } };
// 		});
// 	},
// );

import axios from 'axios';

// import { localStorageLoad } from 'services/store';
// import { sessionStorageLoad } from 'services/store';

export const API = axios.create({
	withCredentials: true,
	baseURL: new URL(process.env.REACT_APP_API_URL).toString(),
});

// API.interceptors.request.use((config) => {
// 	try {
// 		// const { session } = sessionStorageLoad('stremmer');
// 		const { session } = localStorageLoad('stremmer');
// 		if (session?.token) {
// 			config.headers.Authorization = `Bearer ${session.token}`;
// 		}
// 	} catch (error) {
// 		console.error(error);
// 	}
// 	return config;
// });

API.interceptors.response.use(
	(response) => response.data,
	// function (error) {
	// 	return Promise.reject(error);
	// },
);

export function useStremmerApi() {
	return API;
}

import { createSelector } from '@reduxjs/toolkit';

import type { AppState } from 'services/store';

export const selectUser: (state: AppState) => UserState = createSelector(
	(state: AppState): UserState => state.user,
	(user) => user,
);

export const selectUserAccounts: (state: AppState) => UserState['accounts'] = createSelector(
	(state: AppState): UserState => state.user,
	(user) => user.accounts,
);

export const selectUserProfile: (state: AppState) => UserAccount = createSelector(
	(state: AppState): UserAccount[] => state.user.accounts,
	(accounts) => {
		return accounts?.find((account) => account.primary === true);
	},
);

export const selectAccounts: (state: AppState) => UserState['accounts'] = createSelector(
	(state: AppState): UserState => state.user,
	(user) => user.accounts,
);

export function createSelectAccount(id: string): (state: AppState) => UserAccount {
	return createSelector(
		(state: AppState): UserAccount[] => state.user.accounts,
		(accounts): UserAccount => accounts?.find((account) => account._id === id),
	);
}

export function createSelectChannel(id: string): (state: AppState) => UserAccount {
	return createSelector(
		(state: AppState): UserAccount[] => state.user.accounts,
		(accounts): UserAccount => accounts?.find((account) => account._id === id),
	);
}

import { PayloadAction } from '@reduxjs/toolkit';
import { buffers } from 'redux-saga';

export function priorityBuffer() {
	const buffer = buffers.expanding<PayloadAction<any>>();
	const instant = buffers.expanding<PayloadAction<any>>();

	const put = (action: PayloadAction<any>) => {
		const { flags = {} } = action.payload;
		if (flags?.instant === true) {
			return instant.put(action);
		}
		return buffer.put(action);
	};
	const take = (): PayloadAction<any> => {
		if (instant.isEmpty() === false) {
			return instant.take();
		}
		return buffer.take();
	};
	const flush = () => {
		return [...instant.flush(), ...buffer.flush()];
	};
	const isEmpty = () => {
		return instant.isEmpty() && buffer.isEmpty();
	};

	return {
		put,
		take,
		flush,
		isEmpty,
	};
}

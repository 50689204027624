import { Suspense } from 'react';

import { StremmerLoader } from 'components/Ambient/Loader';
import { MainHeader } from './Header';
import { MainFooter } from './Footer';

import './scss/Main.scss';

export function MainContainer({ children }) {
	return (
		<>
			<div className="stremmer-container">
				<MainHeader />
				<Suspense fallback={<StremmerLoader />}>{children}</Suspense>
				<MainFooter />
			</div>
		</>
	);
}

import { nanoid } from '@reduxjs/toolkit';

export const initialModuleState = ({ id, title, type = 'Text', ...rest }: any): OverlayModule => {
	return {
		id: id ?? nanoid(),
		metadata: {
			title: title ?? 'New Layer',
			type,
		},
		...rest,
	} as OverlayModule;
};

export const moduleTemplateState = () => {
	return {
		id: '',
		metadata: {
			title: '',
			type: '',
		},
		defaults: {
			bounds: {
				left: 0,
				top: 0,
				height: 100,
				width: 100,
			},
		},
	};
};

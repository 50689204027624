export const initialOverlayState = () => {
	return {
		metadata: {
			name: '',
			description: '',
			height: 1080,
			width: 1920,
		},
		// layers: [],
		layers: {},
		modules: {},
	} as OverlayState;
};

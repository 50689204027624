import { actionChannel, all, call, fork, put, select, spawn, take } from 'redux-saga/effects';
import { API } from 'helpers/api';

import { assetDelete, assetDeleted, assetsFetch, assetLoaded, assetUpload } from './actions';
import { selectAssetList } from './selectors';

export function* assetSagas() {
	// yield spawn(assetUploadSaga2);
	yield all([fork(assetDeleteSaga), fork(assetFetchSaga), fork(assetUploadSaga)]);
}

function* assetDeleteSaga() {
	const assetsChannel = yield actionChannel([assetDelete]);
	while (true) {
		try {
			const { payload } = yield take(assetsChannel);
			yield call([API, API.delete], `/assets/${payload.id}`);
			yield put(assetDeleted({ id: payload.id }));
		} catch (error) {
			console.error(error);
		}
	}
}

function* assetFetchSaga() {
	const assetsChannel = yield actionChannel([assetsFetch]);
	while (true) {
		try {
			const { payload } = yield take(assetsChannel);
			const loaded = yield select(selectAssetList);
			const list = [...(payload.assets || [])].filter((id: string) => (loaded[id] ? false : true));
			const assets = Array.from(new Set<string>([...list]).values());
			for (const id of assets) {
				yield fork(assetFetchWorker, id);
			}
		} catch (error) {
			console.error(error);
		}
	}
}

function* assetFetchWorker(id: string) {
	try {
		const asset = yield call([API, API.get], `/assets/${id}`);
		yield put(assetLoaded(asset));
	} catch (error) {
		console.error(error);
	}
}

// function* assetUploadSaga2() {}

function* assetUploadSaga() {
	const assetsChannel = yield actionChannel([assetUpload]);
	while (true) {
		try {
			const action = yield take(assetsChannel);
			const abort = new AbortController();
			yield spawn(assetUploadWorker, action, abort.signal);
			// const { payload: file } = yield take(channel);
			// const formData = new FormData();
			// formData.set('asset', file, file.name);
			// const asset = {
			// 	id,
			// 	filename: file.name,
			// 	filetype: file.type,
			// 	filesize: file.size,
			// 	url: URL.createObjectURL(file),
			// 	created_at: new Date().toISOString(),
			// };
		} catch (error) {
			console.error(error);
		}
	}
}

function* assetUploadWorker(action, signal: AbortSignal) {
	try {
		const { uploadId } = action.meta;
		const { file } = action.payload;
		const formData = new FormData();
		formData.set('asset', file, file.name);
		const upload = yield call([API, API.post], `/assets`, formData, { signal });
		yield put(assetLoaded({ ...upload, uploadId }));
	} catch (error) {
		console.error(error);
	}
}

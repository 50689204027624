// import { createSelector } from '@reduxjs/toolkit';

import type { AppState } from 'services/store';
import { composeSelector } from 'services/store/utils/composeSelector';

export const selectOverlay = (state: AppState): OverlayState => (state.overlay?.present ? state.overlay?.present : state.overlay);

export const selectMetadata: (state: AppState) => OverlayMetadata = composeSelector(
	(state: AppState) => selectOverlay(state)?.metadata,
	(metadata) => metadata,
);

export const selectResolution: (state: AppState) => Pick<OverlayMetadata, 'height' | 'width'> = composeSelector(
	(state: AppState): OverlayMetadata => selectOverlay(state)?.metadata,
	(metadata: OverlayMetadata) => {
		return { height: metadata.height, width: metadata.width };
	},
);

import { useCallback, useLayoutEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'services/store';
import { Input, Select } from 'components/Forms';

import eventTypes from './Events.json';

import { getConditionRules, getConditions, getRule, selectVariation } from './Egg/selectors';

import './scss/Conditions.scss';
import { conditionsAdd, conditionsRuleAdd, conditionsRuleDelete, conditionsRuleUpdate } from './Egg/actions';
import { useEventsContext } from './Events.Context';

const operators = [
	// Strings and numbers
	{ operator: 'equal', label: `is exactly` },
	{ operator: 'notEqual', label: `is not` },
	{ factType: 'number', operator: 'lessThan', label: `is less than` },
	{ factType: 'number', operator: 'lessThanInclusive', label: `is less than or equal to` },
	{ factType: 'number', operator: 'greaterThan', label: `is greater than` },
	{ factType: 'number', operator: 'greaterThanInclusive', label: `is greater than or equal to` },
	// Fact in array
	{ valueType: 'array', operator: 'in', label: `is one of` },
	{ valueType: 'array', operator: 'notIn', label: `is not one of` },
	// Array of facts
	// { operator: 'contains', label: `includes` },
	// { operator: 'doesNotContain', label: `doesn't include` },
];

export function ConditionsEditor() {
	const dispatch = useAppDispatch();
	const { selected } = useEventsContext();

	const conditions = useAppSelector((state) => getConditions(selectVariation(state.events, selected)));

	const handleAddGroup = useCallback(() => {
		dispatch(conditionsAdd());
	}, [dispatch]);

	return (
		<>
			<div className="condition-builder">
				<div className="condition-groups">
					{Object.values({ ...(conditions || {}) }).map((condition) => (
						<ConditionsGroup key={condition.id} id={condition.id} />
					))}
				</div>
				<div className="condition-add" onClick={handleAddGroup}>
					<span className="material-icons-outlined">add</span> Add Rule Group
				</div>
			</div>
		</>
	);
}

export function ConditionsGroup({ id }: { id: string }) {
	const dispatch = useAppDispatch();
	const { selected } = useEventsContext();

	const rules = useAppSelector((state) => getConditionRules(selectVariation(state.events, selected), id));

	const handleAddRule = useCallback(() => {
		dispatch(conditionsRuleAdd({ id }));
	}, [dispatch, id]);

	return (
		<div className="condition-groups-item">
			<ul className="condition-rules">
				{Object.values({ ...(rules || {}) }).map((rule) => {
					return (
						<li key={rule.id} className="condition-rules-item">
							<ConditionRule id={rule.id} />
						</li>
					);
				})}
			</ul>
			<div className="condition-add" onClick={handleAddRule}>
				<span className="material-icons-outlined">add</span>
			</div>
		</div>
	);
}

export function ConditionRule({ id }: { id: string }) {
	const dispatch = useAppDispatch();
	const { event, selected } = useEventsContext();

	const rule = useAppSelector((state) => getRule(selectVariation(state?.events, selected), id));

	const facts = eventTypes[event]?.facts;

	const [factType, setFactType] = useState<string>('text');
	const [valueType, setValueType] = useState<string>('text');

	useLayoutEffect(() => {
		try {
			const fact = facts?.find(({ value }) => value === rule?.fact);
			setFactType(fact?.type ?? 'text');
			const value = operators.find(({ operator }) => operator === rule?.operator);
			setValueType(value?.valueType ?? fact?.type ?? 'text');
		} catch (error) {
			console.error(error);
		}
	}, [rule, facts]);

	const handleChange = useCallback(
		(data) => {
			dispatch(conditionsRuleUpdate({ id, rule: { ...data } }));
		},
		[dispatch, id],
	);

	const handleRemove = useCallback(() => {
		dispatch(conditionsRuleDelete({ id }));
	}, [dispatch, id]);

	return (
		<div className="condition-rule">
			<div>If</div>
			<div>
				<Select name="fact" value={rule?.fact} onChange={handleChange}>
					<option value=""></option>
					{facts?.map((fact: any) => (
						<option key={fact?.value} value={fact?.value}>
							{fact?.label}
						</option>
					))}
				</Select>
			</div>
			<div>
				<Select name="operator" value={rule?.operator} onChange={handleChange}>
					<option value=""></option>
					{operators
						?.filter((item) => !item?.factType || item?.factType === factType)
						?.map((operator) => (
							<option key={operator?.operator} value={operator?.operator}>
								{operator?.label}
							</option>
						))}
				</Select>
			</div>
			<div>
				<Input name="value" type={factType} value={rule?.value} onChange={handleChange} placeholder={valueType} />
			</div>
			<div>
				<button type="button" className="btn btn-danger" onClick={handleRemove}>
					<span className="material-icons-outlined">delete</span>
				</button>
			</div>
		</div>
	);
}

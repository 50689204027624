import { createCachedSelector, KeySelector, LruMapCache } from 're-reselect';
import type { AppState } from '../store';

function keySelectorCombiner({ inputSelectors = [] } = {}) {
	const selectors = inputSelectors.map((entry) => entry.keySelector).filter((entry) => typeof entry === 'function');
	return (...args) =>
		selectors
			.map((selector) => selector(...args))
			.filter((value) => [`string`, `number`].includes(typeof value))
			.join(':');
}

type ComposeSelectorTuple = [...KeySelector<AppState>[], (...args: any[]) => any];

export function composeSelector(...selectors: ComposeSelectorTuple) {
	const combiner = selectors.pop();
	return createCachedSelector(
		[...selectors],
		combiner,
	)({
		cacheObject: new LruMapCache({ cacheSize: 69 }),
		keySelectorCreator: keySelectorCombiner,
	});
}

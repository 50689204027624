import classNames from 'classcat';

import './scss/Channel.scss';

export function StremmerAvatar({ account, channel }: { account?: any; channel?: any }): JSX.Element {
	return (
		<>
			<span className={classNames(['stremmer-avatar', { live: channel?.stream?.online }])} title={channel?.stream?.online ? 'Live Now!' : ''}>
				<img src={account.avatar ?? `https://picsum.photos/100/100`} />
			</span>
		</>
	);
}

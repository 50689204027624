import { actionChannel, all, fork, put, select, take } from 'redux-saga/effects';
import WebFont from 'webfontloader';

import type { SagaIterator } from 'redux-saga';

import { fontsLoad, fontsLoaded } from './actions';
import { selectFonts } from './selectors';

export function* fontSagas(): SagaIterator {
	yield all([fork(fontLoaderWorker)]);
}

function* fontLoaderWorker() {
	const channel = yield actionChannel([fontsLoad]);
	while (true) {
		try {
			const { payload } = yield take(channel);
			const { active = [] } = yield select(selectFonts);
			const fonts = [...(payload?.fonts || [])].filter((font: string) => font && active.includes(font) === false);
			const families = Array.from(new Set<string>([...(fonts || [])]).values());
			if (families.length) {
				families.forEach((family: string) => {
					WebFont.load({ google: { api: 'https://fonts.googleapis.com/css2', families: [`${family}&display=swap`] } });
				});
				yield put(fontsLoaded({ fonts: families }));
			}
		} catch (error) {
			console.error(error);
		}
	}
}

import { createReducer } from '@reduxjs/toolkit';

import { assetDelete, assetList, assetLoaded, assetUpload } from './actions';
import { initialAssetsState } from './state';

export const assetsReducer = createReducer(
	() => ({ ...initialAssetsState }),
	(builder) => {
		builder.addCase(assetDelete, (state, { payload }) => {
			delete state.assets[payload.id];
		});

		builder.addCase(assetList.fulfilled, (state, { payload }) => {
			state.assets = { ...payload };
		});

		builder.addCase(assetLoaded, (state, { meta, payload }) => {
			state.assets[payload.id] = { ...payload };
			if (meta?.uploadId) {
				const upload = state.uploads?.[meta.uploadId];
				if (upload) {
					URL.revokeObjectURL(upload.url);
					delete state.uploads[meta.uploadId];
				}
			}
		});

		builder.addCase(assetUpload, (state, { meta, payload }) => {
			const { uploadId: id } = meta;
			const { file } = payload;
			const asset = {
				id,
				filename: file.name,
				filetype: file.type,
				filesize: file.size,
				url: URL.createObjectURL(file),
				created_at: new Date().toISOString(),
			};
			state.uploads[id] = { ...asset };
		});
	},
);

import { lazy } from 'react';
import type { LazyExoticComponent } from 'react';

// import { moduleTypes } from 'components/Editor/Layers';

export const importModuleComponent = async (type: string, component = 'Display'): Promise<LazyExoticComponent<any>> => {
	return lazy(async () => {
		try {
			type = type.charAt(0).toUpperCase() + type.slice(1);
			return await import(`./${type}/${component}`);
			// const module = moduleTypes.find((module) => module.type === type);
			// const dir = module?.module ?? type;
			// console.debug(type, dir);
			// return await import(`./${dir}/${component}`);
		} catch (error) {
			console.error(error);
			return await import(`./Empty`);
		}
	});
};

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'helpers/api';

export const fontsLoad = createAction<{ fonts: string[] }>(`@@fonts/load`);
export const fontsLoaded = createAction<{ fonts: string[] }>(`@@fonts/loaded`);

export const fontsFetch = createAsyncThunk(`@@fonts/fetch`, async () => {
	const data: OverlayFontsState = await API.get('/fonts');
	return data.items;
});

import { spawn } from 'redux-saga/effects';
import { eventModulesSaga } from './module';
import { eventWebWorkerSaga } from './worker';

export function* eventsSagas() {
	// Spawn a WebWorker to handle the EventStream
	yield spawn(eventWebWorkerSaga);
	yield spawn(eventModulesSaga);

	// yield all([
	// 	// fork(eventSourcesSagas),
	// 	// fork(eventMessageSaga),
	// 	// fork(eventRegistrationSaga),
	// 	//
	// 	// fork(rootModuleSaga),
	// 	// fork(eventQueueSaga),
	// 	// fork(eventEmulationSaga),
	// 	// fork(eventActiveHandler),
	// 	// fork(moduleContentSaga),
	// ]);
}

import type { AppState } from '../store';

export function localStorageLoad(name: string): AppState {
	try {
		const storage = localStorage.getItem(name);
		return JSON.parse(storage) as AppState;
	} catch (error) {
		console.warn(error);
		return false;
	}
}

export function localStorageSave(name: string, state: AppState): void {
	try {
		const current = localStorageLoad(name);
		const storage = JSON.stringify({ ...(current || {}), ...state });
		localStorage.setItem(name, storage);
	} catch (error) {
		console.warn(error);
	}
}

export const sessionStorageLoad: (name: string) => AppState = (name) => {
	try {
		const storage = sessionStorage.getItem(name);
		return JSON.parse(storage) as AppState;
	} catch (error) {
		console.warn(error);
		return false;
	}
};

export function sessionStorageSave(name: string, state: AppState): void {
	try {
		const current = sessionStorageLoad(name);
		const storage = JSON.stringify({ ...(current || {}), ...state });
		sessionStorage.setItem(name, storage);
	} catch (error) {
		console.warn(error);
	}
}

// export type StorageOptions = {
// 	name: string;
// 	type: 'local' | 'session';
// 	ignore?: string[];
// 	include?: string[];
// };

// export function storageAdapter(state: AppState) {
// 	Object.entries(state).forEach(([key, value]) => {
// 	})
// }

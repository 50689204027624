import React from 'react';
import ReactDOM from 'react-dom';
import Stremmer from './Stremmer';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import Swal from 'helpers/Swal';

globalThis.Swal = Swal;

TimeAgo.addDefaultLocale(en);

// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
// Sentry.init({
// 	dsn: 'https://9e42ba5437cc4fa597b4bf8f32cef793@o1128800.ingest.sentry.io/6172010',
// 	integrations: [new BrowserTracing()],
// 	environment: process.env.NODE_ENV,
// 	allowUrls: ['stremmer.dev'],
// 	tracesSampleRate: 1.0,
// });

import './index.scss';

ReactDOM.render(
	<React.StrictMode>
		<Stremmer />
	</React.StrictMode>,
	document.getElementById('stremmer-root'),
);

import { createSelector } from '@reduxjs/toolkit';
import { selectModule } from 'components/Overlays/Eggs/selectors';

import type { ConditionProperties, NestedCondition, RuleProperties } from 'json-rules-engine';
import type { AppState } from 'services/store';
// import { composeSelector } from 'services/store/utils/composeSelector';

import { conditionItem } from '../utils';

export const selectEvents = (state: AppState): OverlayEventsState => state.events;

export const selectEventSources: (state: AppState) => string[] = createSelector(
	(state: AppState) => selectEvents(state)?.sources,
	(sources) => Object.keys({ ...sources }),
);

/* -------------------------------------------------- */

export const getEventHistory: (state: AppState, id: string) => OverlayEventMessage = createSelector(
	(state: AppState) => state.events?.history,
	(state: AppState, id: string) => id,
	(history, id) => history?.[id],
);

// export const getEventVariables: (state: AppState, id: string) => OverlayEventMessage[''] = createSelector(
// 	(state: AppState) => state.events?.history,
// 	(state: AppState, id: string) => id,
// 	(history, id) => history?.[id],
// );

export const getEventModule: (state: AppState, id: string) => OverlayEventsModule & { flags: Record<string, boolean> } = createSelector(
	(state: AppState) => state.events?.module,
	(state: AppState, id: string) => id,
	(state: AppState) => state.events?.history,
	(modules, id, history) => {
		const module = modules?.[id];
		const event = history?.[module?.event]?.data;
		const merge = { ...(module || {}), flags: event?.flags } as OverlayEventsModule & { flags: Record<string, boolean> };
		return merge;
		// return modules?.[id];
	},
);

/* -------------------------------------------------- */

export const getEngineRules: (state: AppState, id: string) => RuleProperties[] = createSelector(
	(state: AppState, id: string) => selectModule(state, id),
	(module) => {
		const rules = Object.values({ ...(module?.variations || {}) }).map((variation) => {
			const rule = getModuleRules(variation, module.id);
			rule.event.params = {
				...rule.event.params,
				module: module.id,
				variation: variation.id,
			};
			return rule;
		});
		if (module?.events?.event) {
			const rule = getModuleRules(module, module.id);
			// module.metadata.rule.event.params = { ...rule.event.params, module: module.id };
			// module.metadata.rule.event.params = { ...rule.event.params, module: module.id };
			rules.unshift(rule);
		}
		return rules;
	},
);

const getModuleRules: (state: OverlayModule, module: string) => RuleProperties = createSelector(
	(state: OverlayModule) => state?.events,
	(state: OverlayModule, module: string) => ({ id: state?.id, module, params: {}, primary: state.metadata?.primary ?? false }),
	(events, { id, module, params, primary }) => {
		const custom: NestedCondition[] = Object.values({ ...(events?.conditions || {}) })
			.map(({ id, criteria = 'all' }) => {
				const rules = Object.values({ ...(events?.rules || {}) })
					.filter(({ condition }) => condition === id)
					.filter(({ fact, value }) => fact && value)
					.map((rule): ConditionProperties => conditionItem(events.event, rule));
				return rules.length ? ({ [criteria]: [...rules] } as NestedCondition) : null;
			})
			.filter((rules) => rules !== null);
		const rules = custom?.length ? [{ any: [...custom] }] : [];
		const chance = events?.chance ?? 50;
		const notDefault = primary !== true ? 1 : 0;
		const priority = notDefault + [...Object.keys({ ...(events?.conditions || {}) }), ...Object.keys({ ...(events?.rules || {}) })].length;
		const payload: RuleProperties = {
			conditions: {
				all: [{ fact: 'event', operator: 'equal', value: events?.event }, { fact: module, operator: 'notEqual', value: true }, ...rules],
			},
			event: {
				type: events.event,
				params: { ...(params || {}), id, module, chance },
			},
			name: `rule-${id}`,
			priority,
			onSuccess(_event, almanac) {
				almanac.addRuntimeFact(module, true);
			},
		};
		return payload;
	},
);

/* -------------------------------------------------- */

// export const selectModuleVariables = createSelector(
// 	(state: OverlayModule) => state
// )

/* -------------------------------------------------- */

export const selectVariation: (state: OverlayModule, id: string) => Omit<OverlayModule, 'variations'> = createSelector(
	(state: OverlayModule, id: string) => state?.variations?.[id],
	(variation) => variation,
);

/* -------------------------------------------------- */

export const getEventVariations: (state: OverlayModule, event: string) => OverlayModule['variations'] = createSelector(
	(state: OverlayModule) => state?.variations,
	(_state: OverlayModule, event: string) => event,
	(variations, event) => {
		return Object.values({ ...(variations || {}) })
			.filter(({ events }) => events?.event === event)
			.reduce<OverlayModule['variations']>((out, one) => ({ ...out, [one.id]: one }), {});
	},
	// .filter(({ events }) => Object.keys({ ...(events || {}) }).includes(event))
);

export const getConditions: (state: OverlayModule) => Record<string, EventCondition> = createSelector(
	(state: OverlayModule) => state?.events?.conditions,
	(conditions) => conditions,
);

export const getRule: (state: OverlayModule, id: string) => EventConditionRule = createSelector(
	(state: OverlayModule, id: string) => state?.events?.rules?.[id],
	(rule) => rule,
);

export const getConditionRules: (state: OverlayModule, id: string) => Record<string, EventConditionRule> = createSelector(
	(state: OverlayModule) => state?.events?.rules,
	(state: OverlayModule, id: string) => id,
	(rules, id) =>
		Object.values(rules)
			.filter((rule) => rule.condition === id)
			.reduce((out, one) => ({ ...out, [one.id]: { ...one } }), {}),
);

/* -------------------------------------------------- */

/* -------------------------------------------------- */

export const selectModuleEvents: (state: AppState, module: string) => string[] = createSelector(
	(state: AppState, module: string) => selectModuleVariations(state, module),
	(variations) => {
		const events = Object.values({ ...(variations || {}) }).map((variation) => {
			return variation.events?.event;
		});
		return Array.from(new Set([...events]).values());
	},
);

export const selectModuleVariations: (state: AppState, module: string) => OverlayModule['variations'] = createSelector(
	(state: AppState, module: string) => selectModule(state, module)?.variations,
	(variations) => variations,
);

/* -------------------------------------------------- */

export const selectEventModule: (state: AppState, id: string) => any = createSelector(
	(state: AppState, id: string) => selectEvents(state)?.modules?.[id]?.event,
	(state: AppState, id: string) => selectEvents(state)?.modules?.[id]?.variables,
	(event, variables) => {
		return {
			event: { ...(event || {}) },
			variables: { ...(variables || {}) },
		};
	},
);

/* -------------------------------------------------- */

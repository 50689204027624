import { nanoid } from '@reduxjs/toolkit';

export function createCondition(id = nanoid()): Record<string, EventCondition> {
	return {
		[id]: {
			id,
			criteria: 'all',
			// order: 0,
			// rules: {},
		},
	};
}

export function createConditionRule(condition: string): Record<string, EventConditionRule> {
	const id = nanoid();
	return {
		[id]: {
			id,
			condition,
			fact: '',
			operator: 'equal',
			value: '',
		},
	};
}

/* -------------------------------------------------- */

export function createVariation(event: string, primary = false): Record<string, OverlayModule> {
	// const { template } = alertTypes[event];
	const id = nanoid();
	return {
		[id]: {
			id,
			metadata: {
				enabled: true,
				primary,
				title: `Variation`,
			},
			// alert: {
			// 	duration: 10000,
			// 	animations: {
			// 		enter: {
			// 			name: 'backInDown',
			// 			duration: 1000,
			// 		},
			// 		exit: {
			// 			name: 'backOutUp',
			// 			duration: 1000,
			// 		},
			// 	},
			// },
			events: {
				event,
				conditions: {},
				rules: {},
				duration: 10000,
				animations: {
					enter: {
						name: 'backInDown',
						duration: 1000,
					},
					exit: {
						name: 'backOutUp',
						duration: 1000,
					},
				},
			},
			assets: {
				audio: {},
				video: {},
			},
			content: {
				text: '',
			},
			css: {
				color: '#F54897',
				fontFamily: 'Roboto',
				fontSize: 30,
			},
			// ...(template || {}),
		},
	};
}

/* -------------------------------------------------- */
